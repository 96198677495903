import React, { forwardRef } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { focusIndicatorStyles } from '@moonpig/launchpad-components'
import { IconInformation } from '@moonpig/launchpad-assets'

const BUTTON_SIZE = '48px'

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${BUTTON_SIZE};
  height: ${BUTTON_SIZE};
  cursor: pointer;
  ${s({
    margin: -4,
    color: 'colorTextHeadline',
    borderRadius: 2,
  })}

  &:active {
    ${s({
      color: 'colorInteractionSelectedState',
    })}
  }

  &:not(:disabled) {
    &:hover {
      ${s({
        color: 'colorInteractionIconHover',
      })}
    }
    &:focus {
      ${s({
        color: 'colorInteractionSelectedState',
      })}
    }
  }

  ${focusIndicatorStyles}
`

type Props = {
  ariaLabel: string
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export const InfoPillButton = forwardRef<HTMLButtonElement, Props>(
  ({ ariaLabel, onClick }, ref) => {
    return (
      <StyledButton aria-label={ariaLabel} onClick={onClick} ref={ref}>
        <IconInformation width="20px" height="20px" aria-hidden />
      </StyledButton>
    )
  },
)
