import React, { FC, useEffect, useReducer, useMemo } from 'react'
import { reducer } from './Reducer'
import {
  favouritesDispatcherContext,
  initialState,
  favouritesStateContext,
} from './Context'
import type { Dispatcher } from './types'
import { useLoadFavouritesList } from '../../utils/favourites/useLoadFavouritesList'
import { useAddToFavouritesList } from '../../utils/favourites/useAddToFavouritesList'
import { useRemoveFromFavouritesList } from '../../utils/favourites/useRemoveFromFavouritesList'
import { ToastNotification } from './components/ToastNotification'

export const FavouritesProvider: FC<
  React.PropsWithChildren<{
    location: string
    enableToastNotification: boolean
  }>
> = ({ location, enableToastNotification, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { products, loading } = useLoadFavouritesList()
  const { addToFavouritesList } = useAddToFavouritesList()
  const { removeFromFavouritesList } = useRemoveFromFavouritesList()

  const actions = useMemo<Dispatcher>(
    () => ({
      addToFavourites: ({ productId, removeWithConfirmation }) => {
        return addToFavouritesList(productId)
          .then(updatedFavouritesList => {
            if (updatedFavouritesList) {
              dispatch({
                type: 'UPDATE_FAVOURITES',
                updatedFavourites: updatedFavouritesList.filter(Boolean),
              })
            }
          })
          .then(() => {
            dispatch({ type: 'SHOW_TOAST' })
            return Promise.resolve({ removeWithConfirmation })
          })
      },
      removeFromFavourites: ({ productId, removeWithConfirmation }) => {
        return removeFromFavouritesList(productId)
          .then(updatedFavouritesList => {
            if (updatedFavouritesList) {
              dispatch({
                type: 'UPDATE_FAVOURITES',
                updatedFavourites: updatedFavouritesList.filter(Boolean),
              })
            }
          })
          .then(() => Promise.resolve({ removeWithConfirmation }))
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  useEffect(() => {
    if (!loading) {
      dispatch({
        type: 'INITIALISE',
        favourites: products,
        location,
        isToastShown: false,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  return (
    <favouritesDispatcherContext.Provider value={actions}>
      <favouritesStateContext.Provider value={state}>
        {children}
        {enableToastNotification && state.isToastShown && (
          <ToastNotification
            onAnimationEnd={() => dispatch({ type: 'HIDE_TOAST' })}
            tracking={{ location }}
          />
        )}
      </favouritesStateContext.Provider>
    </favouritesDispatcherContext.Provider>
  )
}
