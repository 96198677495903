import { createContext, useContext } from 'react'
import { ProductDispatcher, ProductState } from './types'

export const stateContext = createContext<ProductState | null>(null)

export const dispatcherContext = createContext<ProductDispatcher | null>(null)

export const useProductStore = (): [ProductState, ProductDispatcher] => {
  const dispatcher = useContext(dispatcherContext)
  const state = useContext(stateContext)

  /* istanbul ignore if */
  if (!state || !dispatcher) {
    throw new Error('ProductStoreProvider is missing.')
  }

  return [state, dispatcher]
}
