import React, { FC } from 'react'
import {
  Box,
  Flex,
  TertiaryButton,
  Text,
  Image,
} from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { IconSystemCrossSmall } from '@moonpig/launchpad-assets'
import { useLocaleText } from './MultiAddOnSelector.locale'
import { AddonsFieldsFragment } from '../../queries/useProductWithAddonsQuery'

const StyledButton = styled(TertiaryButton)`
  text-align: left;
  min-height: auto;
  padding: 0;
  border: 0;
`
const StyledCloseBtn = styled.button`
  display: block;
  width: 28px;
  height: 28px;
  appearance: none;
`

type AddonTileProps = {
  addon: AddonsFieldsFragment
  onClose: () => void
  onProductDetailsClick: () => void
  showSelectedAddonDetails: boolean
}

export const AddonTile: FC<AddonTileProps> = ({
  addon,
  onClose,
  onProductDetailsClick,
  showSelectedAddonDetails,
}) => {
  const { title, price, image, description } = addon

  const localiseText = useLocaleText()

  return (
    <Box>
      <Text typography="typeBodyCaption" display="block">
        {localiseText('multi_addons.item_selected')}
      </Text>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        p={6}
        mt={4}
        border={1}
        borderRadius={1}
        borderColor="colorBlack20"
        bgcolor="colorBackground01"
      >
        <Flex alignItems="center">
          <Box
            mr={6}
            borderRadius={2}
            boxShadow={2}
            overflow="hidden"
            width="75px"
            minWidth="75px"
            aria-hidden
          >
            <Image src={image.url} width="100%" />
          </Box>
          <Box>
            <Text typography="typeBodyLabel" display="block">
              {title}
            </Text>
            <Text my={4} typography="typeBodyLabel" display="block">
              {price.display}
            </Text>
            {description && (
              <StyledButton
                type="button"
                id="mp-shared-products-selected-addon-details-button"
                onClick={onProductDetailsClick}
                aria-expanded={showSelectedAddonDetails}
                aria-label={localiseText(
                  'multi_addons.accordion.accessibility.title',
                  { title },
                )}
              >
                {showSelectedAddonDetails
                  ? localiseText('multi_addons.hide_product_details')
                  : localiseText('multi_addons.see_product_details')}
              </StyledButton>
            )}
          </Box>
        </Flex>
        <Box>
          <StyledCloseBtn
            type="button"
            aria-label={localiseText('multi_addons.accessibility.remove_item', {
              title,
            })}
            onClick={onClose}
          >
            <IconSystemCrossSmall aria-hidden width="100%" height="100%" />
          </StyledCloseBtn>
        </Box>
      </Flex>
    </Box>
  )
}
