export const formatPrice = (
  centAmount: number,
  fractionDigits: number,
  currencyCode: string,
  minimumQuantity = 1,
  locale = 'en',
): string => {
  const decimalPrice = (centAmount * minimumQuantity) / 10 ** fractionDigits

  return decimalPrice.toLocaleString(locale, {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: fractionDigits,
  })
}
