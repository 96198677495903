import React, { FC } from 'react'
import { Flex, Pill, Box } from '@moonpig/launchpad-components'
import { ProductPill } from '../../types'
import { transformPillVariant } from '../../utils/transformPill'

const MAX_PILLS_AMOUNT = 3

export const ProductPills: FC<{
  primaryProductPill?: ProductPill | null
  productPills?: ProductPill[]
}> = ({ primaryProductPill, productPills = [] }) => {
  const pills = (
    primaryProductPill
      ? [primaryProductPill, ...productPills.slice(0, productPills.length - 1)]
      : productPills
  ).slice(0, MAX_PILLS_AMOUNT)

  return (
    <Flex data-testid="mp-products-gift-pills-container">
      {pills.map(pill => (
        <Box key={pill.displayLabel} mr={{ xs: 4, md: 6 }}>
          <Pill
            data-testid="mp-products-product-pill"
            label={pill.displayLabel}
            variant={transformPillVariant(pill.displayVariant)}
          />
        </Box>
      ))}
    </Flex>
  )
}
