import { getCurrencySymbolFromCode } from '@moonpig/web-core-utils'
import { Locale } from '@moonpig/web-core-locale-text'
import { Price, Variant } from '../../types'
import { E_CARD_SIZE_ID } from '../../constants'

type FormatProductVariantsArgs = {
  variants: Variant[]
  selectedVariant: Variant
  id: string
  language: Locale
  eCardDescription?: string
}

const formatPrice = ({
  language,
  fractionDigits,
  price,
}: {
  language: Locale
  fractionDigits: number
  price: number
}) => {
  return Intl.NumberFormat(language, {
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
  }).format(Math.abs(price))
}

const mapPrice = (
  price: Price,
  variant: Variant,
  selectedVariant: Variant,
  language: Locale,
  minimumQuantity: number,
) => {
  const decimalPrice = (price.centAmount * minimumQuantity) / 100
  let currencySymbol = getCurrencySymbolFromCode(price.currencyCode)

  let displayPrice = decimalPrice

  if (variant.key !== selectedVariant.key) {
    const selectedDecimalPrice =
      (selectedVariant.price.centAmount * selectedVariant.minimumQuantity) / 100

    displayPrice -= selectedDecimalPrice

    currencySymbol =
      Math.sign(displayPrice) === -1
        ? `- ${currencySymbol}`
        : `+ ${currencySymbol}`
  }

  const localePrice = formatPrice({
    language,
    fractionDigits: price.fractionDigits,
    price: displayPrice,
  })

  const itemPrice = formatPrice({
    language,
    fractionDigits: price.fractionDigits,
    price: decimalPrice,
  })

  return {
    displayPrice: localePrice,
    itemPrice,
    description: '',
    currencyCode: price.currencyCode,
    currencySymbol,
    centAmount: price.centAmount * minimumQuantity,
    fractionDigits: price.fractionDigits,
  }
}

export const formatProductVariants = ({
  variants,
  selectedVariant,
  id,
  language,
  eCardDescription,
}: FormatProductVariantsArgs) => {
  return variants.map(variant => {
    const subtitle =
      (variant.subtitle || variant?.dimensions?.description) ?? ''

    const description =
      variant.key === E_CARD_SIZE_ID ? eCardDescription : subtitle

    const {
      title,
      price,
      fullPrice,
      discountedPercentage,
      key,
      inStock,
      minimumQuantity,
      sku,
      bundles,
      addons,
    } = variant

    const mappedPrice = mapPrice(
      price,
      variant,
      selectedVariant,
      language,
      minimumQuantity,
    )

    const mappedFullPrice = mapPrice(
      fullPrice,
      variant,
      selectedVariant,
      language,
      minimumQuantity,
    )

    return {
      ...variant,
      label: title,
      price: mappedPrice,
      fullPrice: mappedFullPrice,
      discountedPercentage,
      value: key,
      description,
      selected: key === selectedVariant.key,
      inStock,
      minimumQuantity,
      sku: sku || `${id}-${key}`,
      bundles,
      addons,
    }
  })
}
