import { createContext, useContext } from 'react'
import { BasketDispatcher, BasketState } from './types'

export const stateContext = createContext<BasketState | null>(null)

export const dispatcherContext = createContext<BasketDispatcher | null>(null)

export const useBasket = (): [BasketState, BasketDispatcher] => {
  const dispatcher = useContext(dispatcherContext)
  const state = useContext(stateContext)

  /* istanbul ignore if */
  if (!state || !dispatcher) {
    throw new Error('BasketProvider is missing.')
  }

  return [state, dispatcher]
}
