import { gql, useQuery } from '@moonpig/web-core-graphql'
import React from 'react'
import { LoadingIndicator } from '@moonpig/launchpad-components'
import {
  Region as GraphQLRegion,
  DepartmentsEnum,
} from '@moonpig/web-shared-types-graphql/graphqlTypes'
import { useCoreLocaleText } from '@moonpig/web-core-locale-text'
import { getParentDepartment } from '@moonpig/web-core-utils'
import { useStoreId } from '@moonpig/web-core-stores'
import { ImportedHTML } from '../ImportedHTML'
import {
  DeliveryInformationQuery,
  DeliveryInformationQueryVariables,
} from './__generated__/DeliveryInformation'

type ContentDepartment = {
  id: string
  longDeliveryInfo: string
  subdepartments?: ContentDepartment[]
}

const getLongDeliveryInfo = (
  contentDepartments: ContentDepartment[],
  department: DepartmentsEnum,
) => {
  const departmentsMap = contentDepartments
    .reduce<ContentDepartment[]>((allDepartments, parent) => {
      /* istanbul ignore next */
      return allDepartments.concat([parent, ...(parent.subdepartments || [])])
    }, [])
    .reduce<{ [key: string]: ContentDepartment }>((currentMap, current) => {
      return {
        ...currentMap,
        [current.id]: current,
      }
    }, {})

  /* istanbul ignore next */
  const deliveryInfo =
    departmentsMap[department]?.longDeliveryInfo ||
    departmentsMap[getParentDepartment(department)]?.longDeliveryInfo ||
    ''

  return deliveryInfo
}

export const GetDeliveryInformationGQL = gql`
  query DeliveryInformationQuery(
    $filter: [ID!]
    $preview: Boolean
    $region: Region
  ) {
    contentDepartments(filter: $filter, preview: $preview, region: $region) {
      id
      longDeliveryInfo
      subdepartments {
        id
        longDeliveryInfo
      }
    }
  }
`

type DeliveryInformationProps = {
  department: DepartmentsEnum
  deliveryInformation?: string
}

export const DeliveryInformation: React.FC<DeliveryInformationProps> = ({
  department,
  deliveryInformation,
}) => {
  const storeId = useStoreId()
  const dataLoaded = !!deliveryInformation
  const { data, loading, error } = useQuery<
    DeliveryInformationQuery,
    DeliveryInformationQueryVariables
  >(GetDeliveryInformationGQL, {
    variables: {
      filter: [department, getParentDepartment(department)],
      region: storeId as GraphQLRegion,
      preview: undefined,
    },
    ssr: false,
    skip: dataLoaded,
  })

  const localiseText = useCoreLocaleText()

  if (!dataLoaded && loading)
    return (
      <LoadingIndicator
        data-test-id="loading-indicator"
        label={localiseText('common.loading')}
      />
    )
  /* istanbul ignore next */
  if (!dataLoaded && (error || !data || !data?.contentDepartments)) return null

  return (
    <ImportedHTML
      html={
        deliveryInformation ||
        getLongDeliveryInfo(
          data?.contentDepartments || /* istanbul ignore next */ [],
          department,
        )
      }
    />
  )
}
