import { getParentDepartment } from '@moonpig/web-core-utils'
import { BRAND_LABEL } from '@moonpig/web-core-brand/config'
import { ProductItemEventEnum } from '../types'
import { isSponsoredCheck } from './utils'

type Product = {
  id: string
  slug?: string
  title: string
  customisable?: boolean
  category: {
    id?: number | string
    slug?: string
    name: string
    department?: string
  }
  masterVariant: {
    title: string
    images?: {
      url: string
    }[]
    price: {
      centAmount: number
      currencyCode: string
    }
    inStock: boolean
  }
  rating?: {
    score: number
    count?: number
  } | null
  isFavourite?: boolean
  isSponsored?: boolean
  internallyPromoted?: boolean
}

type ProductSelectItemEventProps = {
  eventType: ProductItemEventEnum
  product: Product
  index: number
  label: string
  listName: string
  variant: string
}

export const createProductItemGAEvent = ({
  eventType,
  product,
  index,
  label,
  listName,
  variant,
}: ProductSelectItemEventProps) => {
  const value = product.masterVariant.price.centAmount / 100
  const gaIndex = index + 1
  const currency = product.masterVariant.price.currencyCode

  return {
    event: eventType,
    ecommerce: {
      currency: currency.toLocaleUpperCase(),
      value,
      items: [
        {
          index: gaIndex,
          item_brand: BRAND_LABEL.toLowerCase(),
          item_category: product.category.name,
          item_category2: product.category.department
            ? getParentDepartment(product.category.department).toLowerCase()
            : undefined,
          item_category3: product.masterVariant.inStock
            ? 'in-stock'
            : 'out-of-stock',
          item_category4: undefined,
          item_category5: product.isFavourite ? 'favourite' : 'non-favourite',
          item_id: product.id.toLowerCase(),
          item_list_name: listName.toLowerCase().substring(0, 500),
          item_name: product.title.toLowerCase(),
          item_variant: variant.toLowerCase(),
          price: Number(value.toFixed(2)),
          quantity: 1,
          is_sponsored: isSponsoredCheck(
            product.isSponsored,
            product.internallyPromoted,
          ),
        },
      ],
    },
    // For GA3 backward compatibility //
    event_data: {
      category: 'product action',
      action: eventType.replace('_', ' '),
      label: label.toLowerCase().substring(0, 500),
      non_interaction: true,
      value: Math.round(value),
    },
    // Web only //
    content_data: undefined,
    discount_data: undefined,
    error_data: undefined,
    // For GTM //
    application_data: {
      datalayer_version: 1,
    },
  }
}
