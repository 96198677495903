import { createContext, useContext } from 'react'
import { Dispatcher, FavouriteProduct, State } from './types'

export const initialState: State = {
  favourites: [],
  loading: true,
  location: '',
  isToastShown: false,
}

export const favouritesStateContext = createContext<State>(initialState)

export const favouritesDispatcherContext = createContext<Dispatcher | null>(
  null,
)

export const useFavouritesState = () => {
  const context = useContext(favouritesStateContext)
  /* istanbul ignore next */
  if (!context) {
    throw new Error('FavouritesProvider is missing.')
  }
  return context
}

export const useFavouritesDispatcher = () => {
  const context = useContext(favouritesDispatcherContext)
  /* istanbul ignore next */
  if (!context) {
    throw new Error('FavouritesProvider is missing.')
  }
  return context
}

export const checkFavourited = (
  favourites: FavouriteProduct[],
  product: { id: string },
) =>
  favourites.some(
    favourite => favourite.id.toLowerCase() === product.id.toLowerCase(),
  )
