import { gql } from '@moonpig/web-core-graphql'

export const GetDigitalGiftDefaultCardGQL = gql`
  query GetDigitalGiftDefaultCard($productId: String!) {
    productLookup(productIds: [$productId]) {
      products {
        voucherOptions {
          defaultCard {
            id
            masterVariant {
              sku
            }
          }
        }
      }
    }
  }
`
