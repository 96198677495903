const getDomain = (pageLocation: string): string => {
  return /* istanbul ignore next */ pageLocation === 'product details'
    ? 'product details'
    : 'product details | popup'
}

export const createProductQuantitySelectorSelectContentGAEvent = ({
  productId,
  quantity,
  pageLocation,
  masterVariantTitle,
}: {
  productId: string
  quantity: number
  pageLocation: string
  masterVariantTitle: string
}) => {
  const domain = getDomain(pageLocation)

  const title = masterVariantTitle.toLowerCase()
  return {
    event: 'select_content',
    content_data: {
      content_type: `${domain} | select quantity | quantity selector`,
      item_id: `${quantity} | ${productId} | ${title}`,
    },
    event_data: {
      category: domain,
      action: 'select quantity',
      label: `quantity selector | ${productId} | ${title}`,
      non_interaction: true,
      value: undefined,
    },
    discount_data: undefined,
    error_data: undefined,
    ecommerce: undefined,
  }
}
