import React, { useState } from 'react'
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Box,
  Text,
} from '@moonpig/launchpad-components'
import {
  IconSystemRocket,
  IconSystemDetails,
  IconSystemStar,
} from '@moonpig/launchpad-assets'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { trackGAEvent } from '@moonpig/web-core-analytics'
import { useCoreLocaleText } from '@moonpig/web-core-locale-text'
import { DepartmentsEnum } from '@moonpig/web-shared-types-graphql/graphqlTypes'
import { ProductReviews } from './ProductReviews/ProductReviews'
import { DeliveryInformation } from './DeliveryInformation'
import { ProductDetailsTabClickGAEvent } from './analytics'

const StyledImportedHTML = styled(Box)`
  ul {
    list-style: disc;
    ${s({ paddingLeft: 10, marginY: 6 })}
  }
  li {
    ${s({ marginBottom: 4 })}
  }
  a {
    color: rgb(25, 26, 81);
    font-weight: bold;
  }
`

type ImportedHTMLProps = {
  html: string
  itemProp?: string
}

const ImportedHTML: React.FC<ImportedHTMLProps> = ({ html, itemProp }) => (
  <StyledImportedHTML>
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: html }}
      itemProp={itemProp}
    />
  </StyledImportedHTML>
)

type Rating = {
  count?: number
}

type ProductTabsProps = {
  productId: string
  description: string
  department: DepartmentsEnum
  deliveryInformation?: string
  rating?: Rating | null
}

export const ProductTabs: React.FC<ProductTabsProps> = ({
  productId,
  description,
  department,
  deliveryInformation,
  rating,
}) => {
  const localiseText = useCoreLocaleText()
  const [showReviews, setShowReviews] = useState(false)
  const [activeTab, setActiveTab] = useState('0')
  const showReviewsTab = rating && rating.count && rating.count > 0

  const handleTabsClick = (value: string) => {
    setActiveTab(value)

    const currentTabName = tabs[value as unknown as number].title
    trackGAEvent(ProductDetailsTabClickGAEvent(currentTabName, productId))

    /* istanbul ignore else */
    if (!showReviews && value === '2') {
      setShowReviews(true)
    }
  }

  const tabs = [
    {
      title: localiseText('common.product_details'),
      icon: <IconSystemDetails width="28px" aria-hidden />,
      content: <ImportedHTML html={description} itemProp="description" />,
    },
    {
      title: localiseText('common.delivery_options'),
      icon: <IconSystemRocket width="28px" aria-hidden />,
      content: (
        <DeliveryInformation
          department={department}
          deliveryInformation={deliveryInformation}
        />
      ),
    },
    {
      title: localiseText('common.reviews'),
      icon: <IconSystemStar width="28px" aria-hidden />,
      showTab: showReviewsTab,
      content: showReviews && <ProductReviews id={productId} />,
    },
  ]

  return (
    <Tabs
      id="productInformationScope"
      data-testid="product-tabs"
      value={activeTab}
      onValueChange={(value: string) => handleTabsClick(value)}
    >
      <TabList>
        {tabs.map(
          ({ title, icon, showTab = true }, i) =>
            showTab && (
              <Tab value={`${i}`} key={`${title}-tab`}>
                {icon}
                <Text marginLeft={4}>{title}</Text>
              </Tab>
            ),
        )}
      </TabList>
      <TabPanels>
        {tabs.map(
          ({ title, content, showTab = true }, i) =>
            showTab && (
              <TabPanel value={`${i}`} key={`${title}-tab-panel`}>
                <Box padding={{ xs: 6, md: 8 }} paddingTop={{ xs: 6, md: 10 }}>
                  {content}
                </Box>
              </TabPanel>
            ),
        )}
      </TabPanels>
    </Tabs>
  )
}
