import { useQuery } from '@moonpig/web-core-graphql'
import { useLoggedIn } from '@moonpig/web-core-auth'
import {
  GetFavouritesQuery as GetFavouritesType,
  GetFavouritesListGQL,
} from '@moonpig/web-shared-favourites'
import { GetFavourites_me_customer_favourites_list_products } from '@moonpig/web-shared-favourites/src/queries/types-graphql'

export function useLoadFavouritesList() {
  const { loggedIn } = useLoggedIn()
  const { data, loading } = useQuery<GetFavouritesType>(GetFavouritesListGQL, {
    ssr: false,
    skip: !loggedIn,
  })

  return {
    products:
      (data?.me?.customer?.favourites?.list?.products.filter(
        Boolean,
      ) as GetFavourites_me_customer_favourites_list_products[]) ||
      /* istanbul ignore next */ [],
    loading,
  }
}
