import React from 'react'
import { Image } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'

const StyledImage = styled(Image).attrs<{
  showShadow: boolean
}>(({ showShadow }) => ({
  className: showShadow ? 'showShadow' : '',
}))<{ showShadow: boolean }>`
  &.isPortraitPostcardBack {
    transform: translateY(50%);
  }
  &.showShadow {
    ${s({
      boxShadow: 2,
    })}
  }
`
const StyledImageWrapper = styled.div.attrs<{
  showShadow: boolean
}>(({ showShadow }) => ({
  className: showShadow ? 'showShadow' : '',
}))<{ showShadow: boolean }>`
  margin: auto;
  max-width: calc(100% - 16px);
  max-height: calc(100% - 16px);
  &.showShadow {
    ${s({
      boxShadow: 2,
    })}
  }
`
export type CardProductCarouselImageProps = {
  isPortraitPostcardBack: boolean
  imageDimensions: {
    width: number
    height: number
  }
  image: {
    src: string
    zoomSrc: string
    alt: string
    lazyLoad: boolean
  }
  'data-testid'?: string
}
export const CardProductCarouselImage: React.FC<
  CardProductCarouselImageProps
> = props => {
  const {
    isPortraitPostcardBack,
    imageDimensions,
    image,
    'data-testid': testId,
  } = props
  return (
    <StyledImageWrapper
      showShadow={!isPortraitPostcardBack}
      data-testid="web-common-find-product-info-image-wrapper"
    >
      <StyledImage
        className={isPortraitPostcardBack ? 'isPortraitPostcardBack' : ''}
        {...image}
        zoomSrc={isPortraitPostcardBack ? '' : image.zoomSrc}
        aspectRatio={
          isPortraitPostcardBack
            ? imageDimensions.height / imageDimensions.width
            : imageDimensions.width / imageDimensions.height
        }
        itemProp="image"
        showShadow={isPortraitPostcardBack}
        data-testid={testId}
        aria-hidden
      />
    </StyledImageWrapper>
  )
}
