import { useMutation } from '@moonpig/web-core-graphql'
import { GetOrCreateDraftDesignMutationGQL } from '../../queries/addDigitalGiftToBasket/getOrCreateDraftDesignMutation'
import {
  GetOrCreateDraftDesignMutationNewMutation,
  GetOrCreateDraftDesignMutationNewMutationVariables,
} from '../../queries/addDigitalGiftToBasket/__generated__/getOrCreateDraftDesignMutation'
import { GetOrCreateDraftDesignMutationNew_getOrCreateDraftDesign_Design as Design } from '../types-graphql'

export type CreateEmptyDesignProps = {
  cardSku: string
  cardId: string
}

export type CreateEmptyDesignResponse = Promise<{
  design: Design
}>

export const useCreateEmptyDesign = () => {
  const [createCardDesign] = useMutation<
    GetOrCreateDraftDesignMutationNewMutation,
    GetOrCreateDraftDesignMutationNewMutationVariables
  >(GetOrCreateDraftDesignMutationGQL)

  const createEmptyDesign = async ({
    cardId,
    cardSku,
  }: CreateEmptyDesignProps): CreateEmptyDesignResponse => {
    const createDesignResult = await createCardDesign({
      variables: {
        input: {
          sku: cardSku,
          productKey: cardId,
          preferOverlayText: true,
          preferOverlayTextFront: true,
        },
      },
    })

    if (
      createDesignResult?.data?.getOrCreateDraftDesign?.__typename !== 'Design'
    ) {
      throw new Error('Could not create card design')
    }

    return { design: createDesignResult.data.getOrCreateDraftDesign }
  }

  return { createEmptyDesign }
}
