import React from 'react'
import { Link } from '@moonpig/web-core-routing'
import { useCoreLocaleText } from '@moonpig/web-core-locale-text'
import { styled } from '@moonpig/launchpad-utils'
import { useStoreId } from '@moonpig/web-core-stores'
import { queryParamsBuilder } from '../../utils/queryParamsBuilder'

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  min-height: 48px;
`

const StyledAnchorLink = styled.a`
  display: flex;
  align-items: center;
  min-height: 48px;
`

export type MoreInfoLinkProps = {
  categorySlug: string
  slug: string
  productId: string
  variantKey?: string
  groupCardProject?: string
}

export const MoreInfoLink: React.FC<MoreInfoLinkProps> = props => {
  const { categorySlug, slug, productId, variantKey, groupCardProject } = props
  const storeId = useStoreId()
  const enableCSR = false
  const localiseText = useCoreLocaleText()

  const queryParams = queryParamsBuilder()
    .add('size', variantKey)
    .add('groupCardProject', groupCardProject)
    .build()

  const href = `/${storeId}/${categorySlug}/p/${slug}/${productId}/${queryParams}`

  /* istanbul ignore if */
  if (enableCSR) {
    return (
      <StyledLink
        name="product-details"
        params={{
          region: storeId,
          departmentSlug: categorySlug,
          slug,
          id: productId,
        }}
      >
        {localiseText('common.more_information')}
      </StyledLink>
    )
  }

  return (
    <StyledAnchorLink href={href}>
      {localiseText('common.more_information')}
    </StyledAnchorLink>
  )
}
