import { createCardVariantIdsEnabledArray } from '../shared/product'
import type { CardVariantsEnabled } from '../types'

export const CARD_VARIANTS_ENABLED: CardVariantsEnabled = {
  'e-cards': true,
  'standard-cards': true,
  'small-cards': false,
  'large-cards': true,
  'giant-cards': true,
  'square-cards': true,
  'large-square-cards': true,
  'giant-square-cards': false,
  'post-cards': true,
}

export const CARD_VARIANT_IDS_ENABLED = createCardVariantIdsEnabledArray(
  CARD_VARIANTS_ENABLED,
)
