import React, { FC, useMemo } from 'react'
import { dispatcherContext } from './Context'
import {
  createPromotionImpressionAction,
  createSelectContentAction,
  createSelectProductAction,
  createViewProductListAction,
} from './Actions'
import { TrackingDispatcher } from './types'
import { useProductStore } from '../product'
import { createPersonaliseProductAction } from './Actions/PersonaliseProduct'
import { useFavouritesState } from '../favourites/Context'

type Props = {
  pageLocation: string
  pageComponent: string
}

/* istanbul ignore next */
export const ProductTrackingProvider: FC<React.PropsWithChildren<Props>> = ({
  children,
  pageLocation,
  pageComponent,
}) => {
  const [{ product, quantity }] = useProductStore()
  const { favourites } = useFavouritesState()

  const actions = useMemo<TrackingDispatcher>(
    () => ({
      selectContent: createSelectContentAction({
        pageLocation,
        product,
      }),
      selectProduct: createSelectProductAction({
        pageLocation,
        favourites,
      }),
      viewProductList: createViewProductListAction({
        pageLocation,
      }),
      personaliseProduct: createPersonaliseProductAction({
        pageComponent,
        product,
        quantity,
      }),
      promotionImpression: createPromotionImpressionAction(),
    }),
    [pageLocation, product, quantity, pageComponent, favourites],
  )

  return (
    <dispatcherContext.Provider value={actions}>
      {children}
    </dispatcherContext.Provider>
  )
}
