import { Duration } from 'luxon'

const getDuration = (time: string) => {
  const [hours, minutes] = time.split(':')

  return Duration.fromObject({
    hours: parseInt(hours, 10),
    minutes: parseInt(minutes, 10),
  })
}

export const getTimeLeftUntilDispatchCutOff = (
  currentTime: string,
  cutOffTime: string,
  localisedTimeParts: { [index: string]: string },
) => {
  const cutOffTotalDuration = getDuration(cutOffTime)
  const currentTimeTotalDuration = getDuration(currentTime)

  if (cutOffTotalDuration > currentTimeTotalDuration) {
    const { hours, minutes } = cutOffTotalDuration
      .minus(currentTimeTotalDuration)
      .normalize()
    const hoursLabel = hours
      ? `${hours} ${
          hours === 1 ? localisedTimeParts.hr : localisedTimeParts.hrs
        }`
      : ''
    const minsLabel = `${minutes} ${
      minutes === 1 ? localisedTimeParts.min : localisedTimeParts.mins
    }`

    return `${hoursLabel} ${minsLabel}`
  }

  return null
}
