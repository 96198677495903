import { ProductPill, ProductPillType, PillVariant } from '../../types'

type Pill = {
  displayLabel: string
  displayVariant: PillVariant
}

export const transformPillVariant = (variant: ProductPillType): PillVariant => {
  switch (variant) {
    case ProductPillType.ECO:
      return 'eco'
    case ProductPillType.MARKETING:
      return 'marketing'
    case ProductPillType.PRICE:
      return 'price'
    case ProductPillType.UNIQUE_SELLING_POINT:
      return 'default'
    case ProductPillType.PLUS:
      return 'plus'
  }
}

export const transformPill = (pill: ProductPill): Pill => {
  return { ...pill, displayVariant: transformPillVariant(pill.displayVariant) }
}
