import React, { useState, useEffect } from 'react'
import { DateTime } from 'luxon'
import { useStore } from '@moonpig/web-core-stores'
import { useCoreLocaleText } from '@moonpig/web-core-locale-text'
import { isWeekendOrBankHoliday } from '../../utils/date-time'
import { getDispatchCutOffMessage } from './getDispatchCutOffMessage'
import { COUNTDOWN_PLACEHOLDER } from './constants'
import { DispatchCutOff } from '../../types'

type DispatchCutOffMessageProps = {
  dispatchCutOff: DispatchCutOff
  hideDispatchCutOff: () => void
}

export const DispatchCutOffMessage: React.FC<DispatchCutOffMessageProps> = ({
  dispatchCutOff,
  hideDispatchCutOff,
}) => {
  const { store } = useStore()
  const localiseText = useCoreLocaleText()
  const localisedTimeParts: { [index: string]: string } = {
    hr: localiseText('common.hr'),
    hrs: localiseText('common.hrs'),
    min: localiseText('common.min'),
    mins: localiseText('common.mins'),
  }

  const { language, timeZone } = store.locale

  const currentDateTime = DateTime.local({ zone: timeZone, locale: language })
  const [currentTime, setCurrentTime] = useState(
    `${currentDateTime.hour}:${currentDateTime.minute}:${currentDateTime.second}`,
  )

  let messageTemplate = dispatchCutOff.weekdays.message
  let dispatchCutOffMessage = getDispatchCutOffMessage(
    messageTemplate,
    currentTime,
    dispatchCutOff.weekdays.time,
    localisedTimeParts,
  )

  if (isWeekendOrBankHoliday(currentDateTime, store)) {
    messageTemplate = dispatchCutOff.weekendsAndBankHolidays.message
    dispatchCutOffMessage = getDispatchCutOffMessage(
      messageTemplate,
      currentTime,
      dispatchCutOff.weekendsAndBankHolidays.time,
      localisedTimeParts,
    )
  }

  if (!dispatchCutOffMessage) hideDispatchCutOff()

  useEffect(() => {
    if (
      dispatchCutOffMessage &&
      messageTemplate.includes(COUNTDOWN_PLACEHOLDER)
    ) {
      const updateCountdownTimer = setTimeout(() => {
        const updatedTime = DateTime.local({ zone: timeZone, locale: language })
        setCurrentTime(
          `${updatedTime.hour}:${updatedTime.minute}:${updatedTime.second}`,
        )
      }, 10000)

      return () => {
        clearTimeout(updateCountdownTimer)
      }
    }

    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTime])

  return dispatchCutOffMessage
}
