import { createLocaleTextHook } from '@moonpig/web-core-locale-text'

export const useLocaleText = createLocaleTextHook({
  dictionary: {
    // MFTT-1714 AB test for outlined cta button on galleries.
    'common.add_to_basket_cta': {
      'en-GB': 'Add to Basket',
      'nl-NL': 'In winkelmand',
    },
    'common.personalise': {
      'en-GB': 'Personalise',
      'nl-NL': 'Personaliseren',
    },
    'common.add_gift_to_a_card': {
      'en-GB': 'Add Gift to a Card',
      'nl-NL': 'Toevoegen aan een kaart',
    },
    'common.discount_details': {
      'en-GB': (discountedPercentage: number) =>
        `(${discountedPercentage}% off)`,
      'nl-NL': (discountedPercentage: number) =>
        `(${discountedPercentage}% korting)`,
    },
    'common.price_explanation': {
      'en-GB': (price: string) => `Price ${price}`,
      'nl-NL': (price: string) => `Prijs ${price}`,
    },
    'common.price_with_discount_explanation': {
      'en-GB': (
        price: string,
        fullPrice: string,
        discountedPercentage: number,
      ) => `Price ${price} was ${fullPrice}. ${discountedPercentage}% off`,
      'nl-NL': (
        price: string,
        fullPrice: string,
        discountedPercentage: number,
      ) => `Prijs ${price} was ${fullPrice}. ${discountedPercentage}% korting`,
    },
    'card_product_tile.add_to_favourites': {
      'en-GB': 'Add this card to your favourites',
      'nl-NL': 'Voeg deze kaart toe aan je favorieten',
    },
    'card_product_tile.remove_from_favourites': {
      'en-GB': 'Remove this card from your favourites',
      'nl-NL': 'Verwijder deze kaart uit je favorieten',
    },
    'non_card_product_tile.more_information': {
      'en-GB': 'More information',
      'nl-NL': 'Meer informatie',
    },
    'non_card_product_tile.add_to_favourites': {
      'en-GB': (product: string) => `Add ${product} to your favourites`,
      'nl-NL': (product: string) => `Voeg ${product} toe aan je favorieten`,
    },
    'non_card_product_tile.remove_from_favourites': {
      'en-GB': (product: string) => `Remove ${product} from your favourites`,
      'nl-NL': (product: string) => `Verwijder ${product} uit je favorieten`,
    },
    our_pick: {
      'en-GB': 'Our Pick',
      'nl-NL': 'Onze keus',
    },
  },
})
