/* eslint-disable no-plusplus */
import { Reducer } from 'react'
import {
  ActionSetDigitalGift,
  ActionSetError,
  ActionSetLoading,
  ActionSetBasketId,
  BasketState,
  ActionSetLastLineItem,
  ActionSetSuccess,
} from './types'

export const reducer: Reducer<
  BasketState,
  | ActionSetLoading
  | ActionSetSuccess
  | ActionSetError
  | ActionSetDigitalGift
  | ActionSetBasketId
  | ActionSetLastLineItem
> = (state, action) => {
  switch (action.type) {
    case 'SET_LOADING': {
      return {
        ...state,
        loading: action.payload,
      }
    }
    case 'SET_SUCCESS': {
      return {
        ...state,
        success: action.payload,
      }
    }
    case 'SET_ERROR': {
      return {
        ...state,
        error: action.payload,
      }
    }
    case 'SET_DIGITAL_GIFT': {
      return {
        ...state,
        digitalGift: action.payload,
      }
    }
    case 'SET_BASKET_ID': {
      return {
        ...state,
        basketId: action.payload,
      }
    }
    case 'SET_LAST_LINE_ITEM': {
      return {
        ...state,
        lastLineItem: action.payload,
      }
    }
    /* istanbul ignore next */
    default:
      return state
  }
}
