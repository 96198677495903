import { gql } from '@moonpig/web-core-graphql'

export const AddToFavouritesListGQL = gql`
  mutation AddToFavouritesList($productIds: [String!]!) {
    addToFavouritesList(productIds: $productIds) {
      products {
        id
      }
    }
  }
`
