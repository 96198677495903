import { VARIANT_INFO_TEXT } from './ProductInfo.locale'

type VariantInfoLocaleType =
  | keyof typeof VARIANT_INFO_TEXT
  | 'common.select_size'

export type TitleVariants = {
  [index: number]: VariantInfoLocaleType
}

const variantsTitleLookup: TitleVariants = {
  10: 'common.select_bouquet_size',
  9: 'common.select_size',
  11: 'common.select_size',
  12: 'common.what_size',
  13: 'common.select_size',
  15: 'common.select_size',
  19: 'common.select_size',
  17: 'common.select_size',
  21: 'common.select_bouquet_size',
  22: 'common.select_size',
  23: 'common.select_size',
  24: 'common.select_size',
  25: 'common.select_size',
  26: 'common.select_size',
  27: 'common.select_size',
  28: 'common.select_size',
  29: 'common.select_size',
  30: 'common.select_size',
  31: 'common.select_size',
  32: 'common.select_size',
  33: 'common.select_size',
  3: 'common.select_size',
  1: 'common.select_size',
}

const variantCategoriesTitleLookup: TitleVariants = {
  12: 'common.who_is_it_for',
}

const variantInfoLinkLookup = {
  12: 'https://support-uk.moonpig.com/hc/en-gb/articles/360015417857-T-Shirts-Size-Guide',
}

const variantInfoLinkTextLookup: TitleVariants = {
  12: 'common.size_info',
}

const infoAlertTitleLookup: TitleVariants = {
  12: 'common.colour',
}

const infoAlertTextLookup: TitleVariants = {
  12: 'common.choose_the_colour_at_the_next_step',
}

export const variantInfoText = {
  variantsTitleLookup,
  variantCategoriesTitleLookup,
  variantInfoLinkLookup,
  variantInfoLinkTextLookup,
  infoAlertTitleLookup,
  infoAlertTextLookup,
}
