import { gql } from '@moonpig/web-core-graphql'

export const AddDigitalGiftToBasket = gql`
  mutation addDigitalGiftToBasket($input: AddDigitalGiftToBasketInput!) {
    addDigitalGiftToBasket(input: $input) {
      __typename
      ... on Basket {
        id
        version
        totalItems
        lineItems {
          title
          sku
          digitalGift {
            id
            sku
            title
          }
        }
      }
      ... on Error {
        message
      }
    }
  }
`
