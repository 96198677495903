import React, { FC, useRef, useState } from 'react'
import { Box, Text } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { AddonTile } from './AddonTile'
import { ImportedHTML } from '../ProductTabs/ImportedHTML'
import { useLocaleText } from './MultiAddOnSelector.locale'
import { trackAddonDeselected, trackTileProductDetailsClick } from './tracking'
import { AddonsFieldsFragment } from '../../queries/useProductWithAddonsQuery'

const StyledCollapsibleBox = styled(Box)`
  transition: max-height 300ms cubic-bezier(0.87, 0, 0.13, 1);
  overflow: hidden;
`

type SelectedAddonDetailsProps = {
  selectedAddon: AddonsFieldsFragment
  onRemoveClick: () => void
}

export const SelectedAddonDetails: FC<SelectedAddonDetailsProps> = ({
  selectedAddon,
  onRemoveClick,
}) => {
  const accordionRef = useRef<HTMLDivElement>(null)
  const localiseText = useLocaleText()
  const [showSelectedAddonDetails, setShowSelectedAddonDetails] =
    useState(false)
  const accordionHight = `${accordionRef.current?.offsetHeight}px`

  return (
    <Box>
      <AddonTile
        addon={selectedAddon}
        onClose={() => {
          onRemoveClick()
          trackAddonDeselected({ sku: selectedAddon.sku })
        }}
        onProductDetailsClick={() => {
          setShowSelectedAddonDetails(!showSelectedAddonDetails)
          trackTileProductDetailsClick({
            sku: selectedAddon.sku,
            show: !showSelectedAddonDetails,
          })
        }}
        showSelectedAddonDetails={showSelectedAddonDetails}
      />
      <StyledCollapsibleBox
        aria-labelledby="mp-shared-products-selected-addon-details-button"
        aria-hidden={!showSelectedAddonDetails}
        maxHeight={showSelectedAddonDetails ? accordionHight : '0px'}
      >
        <Box
          data-testid="mp-shared-products-selected-addon-description"
          ref={accordionRef}
          pt={6}
        >
          <Box
            p={6}
            borderColor="colorBlack30"
            border={1}
            borderLeft={0}
            borderRight={0}
          >
            <Text
              typography="typeButtonLabel"
              display="block"
              pb={6}
              aria-hidden
            >
              {localiseText('multi_addons.accordion.title')}
            </Text>
            <ImportedHTML html={selectedAddon.description} />
          </Box>
        </Box>
      </StyledCollapsibleBox>
    </Box>
  )
}
