import React from 'react'
import { breakpoint, styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { getTimeLeftUntilDispatchCutOff } from './getTimeLeftUntilDispatchCutOff'
import { COUNTDOWN_PLACEHOLDER } from './constants'

const StyledText = styled.p`
  ${s({ typography: 'typeDisplay05', m: 0 })}

  ${breakpoint('lg')} {
    font-size: 18px;
  }
`

const StyledTimeText = styled.span`
  ${s({
    typography: 'typeDisplay05',
    color: 'colorTextBody',
  })}

  ${breakpoint('lg')} {
    font-size: 18px;
  }
`

export const getDispatchCutOffMessage = (
  messageTemplate: string,
  currentTime: string,
  cutOffTime: string,
  localisedTimeParts: { [index: string]: string },
): JSX.Element | null => {
  if (!messageTemplate) return null

  if (!messageTemplate.includes(COUNTDOWN_PLACEHOLDER)) {
    return (
      <StyledText data-testid="web-find-dispatch-cut-off-message">
        {messageTemplate}
      </StyledText>
    )
  }

  const timeLeftUntilCutOff = getTimeLeftUntilDispatchCutOff(
    currentTime,
    cutOffTime,
    localisedTimeParts,
  )

  if (timeLeftUntilCutOff) {
    const [messageStart, messageEnd] = messageTemplate.split(
      COUNTDOWN_PLACEHOLDER,
    )

    return (
      <StyledText data-testid="web-find-dispatch-cut-off-message">
        {messageStart}
        <StyledTimeText>{timeLeftUntilCutOff}</StyledTimeText>
        {messageEnd}
      </StyledText>
    )
  }

  return null
}
