import { gql } from '@moonpig/web-core-graphql'

export const RemoveFromFavouritesListGQL = gql`
  mutation RemoveFromFavouritesList($productIds: [String!]!) {
    removeFromFavouritesList(productIds: $productIds) {
      products {
        id
        title
        slug
        dependencies
        category {
          slug
          name
          id
          department
        }
        customisable
        isLandscape
        rating {
          count
          score
        }
        masterVariant {
          key
          title
          images {
            url
          }
          price {
            centAmount
            currencyCode
            fractionDigits
          }
          fullPrice {
            centAmount
            currencyCode
            fractionDigits
          }
          discountedPercentage
          minimumQuantity
          masterImage {
            url
          }
          inStock
          sku
          bundles {
            description
            price {
              currencyCode
              centAmount
              fractionDigits
            }
            discountPercentage
            size
          }
        }
        variants {
          key
          title
          price {
            currencyCode
            centAmount
            fractionDigits
          }
          fullPrice {
            centAmount
            currencyCode
            fractionDigits
          }
          discountedPercentage
          inStock
          minimumQuantity
          sku
          bundles {
            description
            price {
              currencyCode
              centAmount
              fractionDigits
            }
            discountPercentage
            size
          }
        }
        hasAugmentedReality
        isSponsored
        clickRankDocumentCount
      }
    }
  }
`
