import React, { FC, useMemo, useReducer } from 'react'
import { ProductContext, ProductInfoProduct, UpsellProduct } from '../../types'
import { dispatcherContext, stateContext } from './Context'
import { findVariantOrDefault, reducer } from './Reducer'
import { ProductDispatcher } from './types'
import {
  createResetAction,
  createSelectQuantityAction,
  createSelectVariantAction,
  createSelectAddonAction,
  createSetProductAction,
  createSetIsGroupCardSelectedAction,
} from './Actions'
import { DEFAULT_QUANTITY } from './constants'

type Props = {
  initialProduct: ProductInfoProduct
  upsellProduct?: UpsellProduct
  initialVariantKey?: string
  productContext?: ProductContext
}

/* istanbul ignore next */
export const ProductStoreProvider: FC<React.PropsWithChildren<Props>> = ({
  children,
  initialProduct,
  upsellProduct,
  initialVariantKey,
  productContext,
}) => {
  const initialVariant = findVariantOrDefault(initialProduct, initialVariantKey)
  const [state, dispatch] = useReducer(reducer, {
    initialProduct,
    product: initialProduct,
    initialVariantKey,
    initialVariant,
    variant: initialVariant,
    upsellProduct: upsellProduct as UpsellProduct,
    quantity: DEFAULT_QUANTITY,
    addon: null,
    productContext,
    isGroupCardSelected: false,
  })

  const actions = useMemo<ProductDispatcher>(
    () => ({
      setProduct: createSetProductAction(dispatch),
      selectVariant: createSelectVariantAction(dispatch),
      selectQuantity: createSelectQuantityAction(dispatch),
      selectAddon: createSelectAddonAction(dispatch),
      setIsGroupCardSelected: createSetIsGroupCardSelectedAction(dispatch),
      reset: createResetAction(dispatch),
    }),
    [],
  )

  return (
    <dispatcherContext.Provider value={actions}>
      <stateContext.Provider value={state}>{children}</stateContext.Provider>
    </dispatcherContext.Provider>
  )
}
