import { DateTime } from 'luxon'
import { Store } from '@moonpig/web-core-types'
import { holidaysData } from './holidaysData'

export const isWeekendOrBankHoliday = (dateTime: DateTime, store: Store) => {
  const storeHolidays = holidaysData[store.locale.countryCode]
  return (
    dateTime.weekdayLong === 'Saturday' ||
    dateTime.weekdayLong === 'Sunday' ||
    storeHolidays.includes(
      dateTime.toISODate() || /* istanbul ignore next */ '',
    )
  )
}
