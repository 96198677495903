import React from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { Box } from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'

const StyledImportedHTML = styled(Box)`
  ul {
    list-style: disc;
    ${s({ paddingLeft: 10, marginY: 6 })}
  }

  li {
    ${s({ marginBottom: 4 })}
  }
`

type ImportedHTMLProps = {
  html: string
  itemProp?: string
}

export const ImportedHTML: React.FC<ImportedHTMLProps> = ({
  html,
  itemProp,
}) => (
  <StyledImportedHTML>
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: html }}
      itemProp={itemProp}
    />
  </StyledImportedHTML>
)
