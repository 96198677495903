import { createLocaleTextHook } from '@moonpig/web-core-locale-text'

export const useMultiVariantSelectorLocaleText = createLocaleTextHook({
  dictionary: {
    'common.flower_variant_description_18': {
      'en-GB': 'To show them you care',
      'nl-NL': 'Laat zien dat je om hen geeft',
    },
    'common.flower_variant_description_23': {
      'en-GB': 'Moonpig favourite',
      'nl-NL': 'Favoriet op Greetz',
    },
  },
})
