import { Dropdown } from '@moonpig/launchpad-forms'
import React, { FC } from 'react'
import { styled, breakpoint } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { spacingPx } from '@moonpig/launchpad-theme'

const COMPONENT_MIN_WIDTH_PX = '144px'

const StyledDropdown = styled(Dropdown)<{ fullWidth?: boolean }>`
  display: grid;
  grid-auto-flow: column;
  grid-gap: ${spacingPx(4)};
  align-items: center;
  justify-content: ${({ fullWidth }) =>
    fullWidth ? 'space-between' : 'right'};
  height: 100%;

  ${breakpoint('md')} {
    margin-left: ${({ fullWidth }) => (fullWidth ? 'unset' : spacingPx(4))};
  }

  & {
    ${s({ minWidth: COMPONENT_MIN_WIDTH_PX })};
  }

  label {
    ${s({
      typography: { xs: 'typeMobileDisplay03', md: 'typeDesktopDisplay05' },
      color: 'colorTextHeadline',
    })};
    ${({ fullWidth }) => {
      if (fullWidth) return `padding-bottom: 0`
    }}
  }
`

type QuantitySelectorProps = {
  id: string
  quantity: number
  maxQuantity: number
  onQuantityChange?: (newQuantity: number) => void
  label: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  width: any
  disabled: boolean
  fullWidth?: boolean
}

export const QuantitySelector: FC<QuantitySelectorProps> = ({
  id,
  quantity,
  maxQuantity,
  onQuantityChange,
  label,
  width,
  disabled,
  fullWidth,
}) => {
  const quantityList: { value: string; label: string }[] = Array.from(
    { length: maxQuantity },
    (_, i) => i + 1,
  ).map(value => ({ value: value.toString(), label: value.toString() }))

  return (
    <StyledDropdown
      name={id}
      label={label}
      value={quantity.toString()}
      options={quantityList}
      onChange={e =>
        onQuantityChange && onQuantityChange(Number(e.target.value))
      }
      width={width}
      disabled={disabled}
      fullWidth={fullWidth}
    />
  )
}
