import { getDepartmentTitle } from '@moonpig/web-core-utils'
import { DepartmentsEnum } from '@moonpig/web-shared-types-graphql/graphqlTypes'
import { BRAND_LABEL } from '@moonpig/web-core-brand/config'
import { isSponsoredCheck } from './utils'

type Price = {
  currencyCode: string
  centAmount: number
}

type Variant = {
  title: string
  price: Price
  inStock: boolean
}

type Product = {
  id: string
  title: string
  masterVariant: Variant
  isFavourite?: boolean | null
  category: {
    name: string
    department: DepartmentsEnum
  }
  isSponsored: boolean
  internallyPromoted?: boolean
}

type ProductBundle = {
  item_brand: string
  item_name: string
  item_id: string
  item_variant: string
  item_category: string
  item_category2: string
  item_category3: string
  item_category4: string
  item_category5: undefined
  index: number
  item_list_name: string
  item_list_id?: string
  price: number
  quantity: number
}

type GalleryData = {
  number_of_results: number
  product_department: string
  product_filter: string
  product_term: string
  page_title: string
}

type SearchData = {
  index: string
  input_type: string
  number_of_results: number
  product_department: string
  search_term: string
  page_title: string
}

type ProductListGAEventDetails = {
  products: Product[]
  label: string
  action?: string
  event?: string
  productsPreviouslyLoaded?: number
  sort_by?: string
} & (
  | { gallery_data: GalleryData; search_data?: never }
  | { search_data: SearchData; gallery_data?: never }
  | { gallery_data?: undefined; search_data?: undefined }
)

type ProductListGAEventDetailsBundle = {
  products: ProductBundle[]
  totalValue: number
  currency: string
  label: string
  action?: string
  event?: string
  productIndex?: number
}

export const createViewItemListGAEvent = ({
  products,
  label,
  gallery_data,
  search_data,
  productsPreviouslyLoaded,
  sort_by,
}: ProductListGAEventDetails) => {
  const value = products.length
    ? Number(
        products.reduce(
          (total, product) => total + product.masterVariant.price.centAmount,
          0,
        ) / 100,
      )
    : 0
  let eventCategory = 'product action'
  let eventAction = 'view item list'
  if (gallery_data !== undefined) {
    eventCategory = 'gallery'
    eventAction = 'view gallery results'
  }
  if (search_data !== undefined) {
    eventCategory = 'search'
    eventAction = 'view search results'
  }

  const currency = products[0]?.masterVariant.price.currencyCode

  return {
    event: 'view_item_list',
    ecommerce: {
      currency: currency?.toLocaleUpperCase(),
      value,
      items: products.map((product, index) => ({
        index: productsPreviouslyLoaded
          ? productsPreviouslyLoaded + index + 1
          : index + 1,
        item_brand: BRAND_LABEL.toLowerCase(),
        item_category: product.category.name.toLowerCase(),
        item_category2: getDepartmentTitle(product.category.department),
        item_category3: product.masterVariant.inStock
          ? 'in-stock'
          : 'out-of-stock',
        item_category4: undefined,
        item_category5: product.isFavourite ? 'favourite' : 'non-favourite',
        item_id: product.id.toLowerCase(),
        item_list_id: undefined,
        item_list_name: label.toLowerCase(),
        item_name: product.title.toLowerCase(),
        item_variant: product.masterVariant.title.toLowerCase(),
        price: Number(
          (product.masterVariant.price.centAmount / 100).toFixed(2),
        ),
        quantity: 1,
        is_sponsored: isSponsoredCheck(
          product.isSponsored,
          product.internallyPromoted,
        ),
      })),
    },
    gallery_data,
    search_data,
    // For GA3 backward compatibility //
    event_data: {
      category: eventCategory,
      action: eventAction,
      label: label.toLowerCase(),
      non_interaction: true,
      value: Math.round(value),
      sort_by,
    },
    // Web only //
    content_data: undefined,
    discount_data: undefined,
    error_data: undefined,
    // For GTM //
    application_data: {
      datalayer_version: 1,
    },
  }
}

export const createViewItemListGAEventBundleMiniCards = ({
  products,
  totalValue,
  currency,
  label,
}: ProductListGAEventDetailsBundle) => {
  return {
    event: 'view_item_list',
    ecommerce: {
      currency: currency.toLocaleUpperCase(),
      value: totalValue,
      items: products,
    },
    // For GA3 backward compatibility //
    event_data: {
      category: 'product action',
      action: 'view item list',
      label: label.toLowerCase(),
      non_interaction: true,
      value: Math.round(totalValue),
    },
    // Web only //
    content_data: undefined,
    discount_data: undefined,
    error_data: undefined,
    // For GTM //
    application_data: {
      datalayer_version: 1,
    },
  }
}
