import { CORE_TEXT, createLocaleTextHook } from '@moonpig/web-core-locale-text'

export const VARIANT_INFO_TEXT = {
  'common.select_size': {
    'en-GB': 'Select Size',
    'nl-NL': 'Kies het formaat',
  },
  'common.what_size': { 'en-GB': 'What Size?', 'nl-NL': 'Welk formaat?' },
  'common.select_bouquet_size': {
    'en-GB': 'Select bouquet size',
    'nl-NL': 'Selecteer formaat boeket',
  },
  'common.flower_variant_description_standard': {
    'en-GB': 'To show them you care',
    'nl-NL': 'Laat zien dat je om hen geeft',
  },
  'common.flower_variant_description_large': {
    'en-GB': 'Moonpig favourite',
    'nl-NL': 'Favoriet op Greetz',
  },
  'common.who_is_it_for': {
    'en-GB': 'Who is it for?',
    'nl-NL': 'Voor wie is het?',
  },
  'common.size_info': {
    'en-GB': 'Size info',
    'nl-NL': 'Informatie over het formaat',
  },
  'common.colour': { 'en-GB': 'Colour', 'nl-NL': 'Kleur' },
  'common.choose_the_colour_at_the_next_step': {
    'en-GB': 'Choose the colour at the next step',
    'nl-NL': 'Kies de kleur bij de volgende stap',
  },
}

export const GROUP_CARD = {
  'group_card.make_group_card': {
    'en-GB': 'Make Group Card',
    'nl-NL': 'Groepswenskaart maken',
  },
}

export type ProductInfoDictionary =
  | keyof typeof CORE_TEXT
  | keyof typeof VARIANT_INFO_TEXT
  | keyof typeof GROUP_CARD

export const PRODUCT_INFO_TEXT = {
  ...CORE_TEXT,
  ...VARIANT_INFO_TEXT,
  ...GROUP_CARD,
  'common.add_gift_to_a_card': {
    'en-GB': 'Add Gift to a Card',
    'nl-NL': 'Toevoegen aan een kaart',
  },
}

export const useProductInfoLocaleText = createLocaleTextHook({
  dictionary: PRODUCT_INFO_TEXT,
})
