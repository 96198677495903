import { createLocaleTextHook } from '@moonpig/web-core-locale-text'

export const useGiftImageCarouselLocaleText = createLocaleTextHook({
  dictionary: {
    'common.gift_image_carousel.root_title_text': {
      'en-GB': (c: { title: string }) => `${c.title} image gallery`,
      'nl-NL': (c: { title: string }) => `${c.title} fotobibliotheek`,
    },
  },
})
