import React, { FC } from 'react'
import {
  Accordion,
  AccordionContent,
  AccordionHeading,
  Box,
  Heading,
  Text,
} from '@moonpig/launchpad-components'
import { ImportedHTML } from '../ProductTabs/ImportedHTML'
import { useLocaleText } from './MultiAddOnSelector.locale'
import { AddonsFieldsFragment } from '../../queries/useProductWithAddonsQuery'

type AddonDetailsAccordionProps = {
  addon: AddonsFieldsFragment
  onValueChange: (value: string) => void
}

export const AddonDetailsAccordion: FC<AddonDetailsAccordionProps> = ({
  addon: { title, description },
  onValueChange,
}) => {
  const localiseText = useLocaleText()

  return (
    <Box>
      <Accordion
        type="single"
        collapsible
        bgcolor="colorBackground06"
        onValueChange={onValueChange}
      >
        <AccordionContent
          heading={
            <AccordionHeading level="h3">
              <Text
                typography="typeButtonLabel"
                aria-label={localiseText(
                  'multi_addons.accordion.accessibility.title',
                  { title },
                )}
              >
                {localiseText('multi_addons.accordion.title')}
              </Text>
            </AccordionHeading>
          }
          value="addon-more-details"
        >
          <Box data-testid="mp-shared-products-addon-description">
            <Heading level="h5">{title}</Heading>
            <ImportedHTML html={description} />
          </Box>
        </AccordionContent>
      </Accordion>
    </Box>
  )
}
