import React from 'react'
import { ImageCarousel } from '@moonpig/web-shared-components'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { Box } from '@moonpig/launchpad-components'
import { FavouriteButton } from '../FavouriteButton'

const NAV_BUTTON_WIDTH = 48
const FAV_CONTAINER_BOTTOM_POS = {
  portrait: '99px',
  landscape: '87px',
}
const FAV_TOGGLE_RIGHT_OFFSET = {
  mobile: '12px',
  desktop: '60px',
}

const FavouriteContainer = styled.div<{
  maxWidth: number | string
  orientation: string
}>`
  position: absolute;
  left: 50%;
  bottom: ${({ orientation }) =>
    `${
      orientation === 'portrait'
        ? FAV_CONTAINER_BOTTOM_POS.portrait
        : FAV_CONTAINER_BOTTOM_POS.landscape
    }`};
  width: 100%;
  max-width: ${({ maxWidth }) => `${maxWidth}px`};
  height: 0;
  transform: translateX(-50%);
`

const StyledFavouriteButton = styled(FavouriteButton)<{
  hasContainer?: boolean
}>`
  position: absolute;
  ${({ hasContainer }: { hasContainer?: boolean }) =>
    s({
      right: hasContainer
        ? {
            xs: FAV_TOGGLE_RIGHT_OFFSET.mobile,
            md: FAV_TOGGLE_RIGHT_OFFSET.desktop,
          }
        : 12,
      bottom: hasContainer ? 'unset' : 4,
    })}
`

export const CardImageCarousel = ({
  carouselItems,
  id,
  title,
  imageDimensions,
  isFavourited,
  onFavouriteToggle,
  initialItemIndex,
}: {
  carouselItems: JSX.Element[]
  id: string
  title: string
  imageDimensions?: {
    width: number
    height: number
  }
  isFavourited: boolean
  onFavouriteToggle: () => void
  initialItemIndex?: number
}) => {
  return (
    <div
      style={{ position: 'relative' }}
      data-testid="mp-find-card-image-carousel"
      data-product-id={id}
    >
      {carouselItems.length > 1 ? (
        <>
          <ImageCarousel
            key={id}
            items={carouselItems}
            title={title}
            imageDimensions={imageDimensions}
            initialItemIndex={initialItemIndex}
          />
          <FavouriteContainer
            maxWidth={
              (imageDimensions &&
                imageDimensions.width + NAV_BUTTON_WIDTH * 2) ||
              'auto'
            }
            orientation={
              imageDimensions && imageDimensions.width > imageDimensions.height
                ? 'landscape'
                : 'portrait'
            }
          >
            <StyledFavouriteButton
              title={title || id}
              isFavourited={isFavourited}
              onFavouriteToggle={onFavouriteToggle}
              isCard
              hasContainer
            />
          </FavouriteContainer>
        </>
      ) : (
        <>
          <Box
            position="relative"
            maxWidth={imageDimensions?.width}
            margin="auto"
          >
            {carouselItems[0]}
            <StyledFavouriteButton
              title={title || id}
              isFavourited={isFavourited}
              onFavouriteToggle={onFavouriteToggle}
              isCard
            />
          </Box>
        </>
      )}
    </div>
  )
}
