import { createLocaleTextHook } from '@moonpig/web-core-locale-text'

const dictionary = {
  'multi_addons.section.title': {
    'en-GB': 'Make It Complete',
    'nl-NL': 'Maak het compleet',
  },
  'multi_addons.accessibility.title': {
    'en-GB': 'Make it complete by adding something extra',
    'nl-NL': "Maak het compleet met iets extra's",
  },
  'multi_addons.accordion.title': {
    'en-GB': 'More Details',
    'nl-NL': 'Omschrijving',
  },
  'multi_addons.accordion.accessibility.title': {
    'en-GB': ({ title }: { title: string }) => `${title} details`,
    'nl-NL': ({ title }: { title: string }) => `Omschrijving ${title}`,
  },
  'multi_addons.see_product_details': {
    'en-GB': 'See product details',
    'nl-NL': 'Bekijk de omschrijving',
  },
  'multi_addons.hide_product_details': {
    'en-GB': 'Hide product details',
    'nl-NL': 'Omschrijving verbergen',
  },
  'multi_addons.item_selected': {
    'en-GB': 'Item selected:',
    'nl-NL': 'Je hebt dit gekozen:',
  },
  'multi_addons.add_this_item': {
    'en-GB': 'Add This Item',
    'nl-NL': 'Toevoegen',
  },
  'multi_addons.accessibility.add_this_item': {
    'en-GB': ({ title }: { title: string }) => `Attach this ${title}`,
    'nl-NL': ({ title }: { title: string }) => `${title} toevoegen`,
  },
  'multi_addons.accessibility.remove_item': {
    'en-GB': ({ title }: { title: string }) => `Remove ${title}`,
    'nl-NL': ({ title }: { title: string }) => `${title} verwijderen`,
  },
}

export const useLocaleText = createLocaleTextHook({
  dictionary,
})
