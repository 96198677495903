import {
  Alert,
  Box,
  Flex,
  Heading,
  LoadingIndicator,
  Text,
} from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import React from 'react'
import { useCoreLocaleText } from '@moonpig/web-core-locale-text'

const SpinnerOverlay = styled(Box)<{ fixed: boolean; zIndex?: number }>`
  ${s({ bgcolor: 'colorBackground01' })}
  ${({ fixed, zIndex = 2040 }) =>
    `${
      fixed
        ? `
  position: fixed;
  right: 0;
  bottom: 0;
  `
        : `
  position: absolute;
  width: 100%;
  height: 100%;
  `
    }
  left: 0;
  top: 0;
  opacity: 0.7;
  z-index: ${zIndex};
  `}
`

const AlertContainer = styled(Box)`
  position: fixed;
  z-index: 2001;
  top: 36px;
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);
  max-width: 600px;
  margin: auto;
  pointer-events: all;
  ${s({
    paddingX: 4,
  })}

  animation: alert-fade-in 500ms;

  @keyframes alert-fade-in {
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 30px;
      opacity: 1;
    }
  }
`

type StatusProps = {
  error?: {
    status: boolean
    onDismiss?: () => void
  }
  loading?: {
    status: boolean
    fixed?: boolean
    zIndex?: number
  }
}

export const Status: React.FC<StatusProps> = ({ error, loading }) => {
  const localiseText = useCoreLocaleText()
  const { status: showLoading, fixed = true, zIndex } = loading || {}
  const { status: showerror, onDismiss } = error || {}

  return (
    <>
      {showLoading && (
        <SpinnerOverlay
          fixed={fixed}
          zIndex={zIndex}
          data-testid={'status-spinner-overlay'}
        >
          <Box height={1}>
            <Flex
              width="100%"
              height="100%"
              justifyContent="center"
              alignItems={fixed ? 'center' : 'top'}
            >
              <LoadingIndicator label={localiseText('common.loading')} />
            </Flex>
          </Box>
        </SpinnerOverlay>
      )}
      {showerror && (
        <AlertContainer>
          <Alert variant="error" onClickClose={onDismiss} my={5}>
            <Heading
              level="h4"
              mb={0}
              typography={{
                xs: 'typeMobileDisplay05',
                md: 'typeDesktopDisplay06',
              }}
            >
              {localiseText('common.oops')}
            </Heading>
            <Text>{localiseText('common.error_try_again')}</Text>
          </Alert>
        </AlertContainer>
      )}
    </>
  )
}
