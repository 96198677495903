import { useEffect, useCallback } from 'react'
import { compressToBase64, decompressFromBase64 } from 'lz-string'
import {
  AddFavouriteGAEvent,
  RemoveFavouriteGAEvent,
  Product,
} from '@moonpig/web-shared-favourites'
import { trackGAEvent } from '@moonpig/web-core-analytics'
import { useLoggedIn } from '@moonpig/web-core-auth'
import { redirect } from '@moonpig/web-core-routing'
import { useStoreId } from '@moonpig/web-core-stores'
import { isSessionStorageAvailable } from '@moonpig/web-core-utils'
import { useFavouritesDispatcher } from '../../contexts/favourites/Context'

export function useHandleFavourite<TProduct extends Product>({
  removeWithConfirmation,
  tracking,
}: {
  removeWithConfirmation: boolean
  tracking: {
    totalCount: number
    pageLocation: string
  }
}) {
  const { loggedIn } = useLoggedIn()
  const storeId = useStoreId()
  const favouritesDispatcher = useFavouritesDispatcher()
  const { totalCount, pageLocation } = tracking

  const handleFavourite = useCallback(
    (product: TProduct, isFavouriteSelected: boolean, index: number) => {
      if (!loggedIn) {
        const favourites = compressToBase64(
          JSON.stringify({
            product,
            isFavouriteSelected,
            index,
          }),
        )
        sessionStorage.setItem('favourites', favourites)

        trackGAEvent(
          AddFavouriteGAEvent({
            product,
            index,
            total: totalCount,
            page: pageLocation,
            isLoggedIn: Boolean(loggedIn),
          }),
        )

        redirect({
          path: `/${storeId}/account/login/`,
          returnUrl: window.location.pathname + window.location.search,
        })

        return Promise.resolve({ removeWithConfirmation })
      }

      if (isFavouriteSelected) {
        trackGAEvent(
          RemoveFavouriteGAEvent({
            product,
            index,
            total: totalCount,
            page: pageLocation,
          }),
        )

        return favouritesDispatcher.removeFromFavourites({
          productId: product.id,
          removeWithConfirmation,
        })
      }

      trackGAEvent(
        AddFavouriteGAEvent({
          product,
          index,
          total: totalCount,
          page: pageLocation,
          isLoggedIn: Boolean(loggedIn),
        }),
      )

      return favouritesDispatcher.addToFavourites({
        productId: product.id,
        removeWithConfirmation,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tracking, loggedIn],
  )

  useEffect(() => {
    const favourites =
      isSessionStorageAvailable() && sessionStorage.getItem('favourites')

    if (loggedIn && favourites) {
      const { product, isFavouriteSelected, index } = JSON.parse(
        decompressFromBase64(favourites),
      )

      handleFavourite(product, isFavouriteSelected, index)
      sessionStorage.removeItem('favourites')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn])

  return handleFavourite
}
