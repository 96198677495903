import { trackGAEvent } from '@moonpig/web-core-analytics'
import { createProductItemGAEvent } from '../../../analytics'
import { ProductItemEventEnum } from '../../../types'
import { SelectProductProps } from '../types'
import { FavouriteProduct } from '../../favourites'

type Args = {
  pageLocation: string
  favourites: FavouriteProduct[]
}

export const createSelectProductAction =
  ({ pageLocation, favourites }: Args) =>
  (payload: SelectProductProps) => {
    const { product, list, variant, component, index } = payload

    const isFavourite = favourites.map(f => f.id).includes(product.id)

    const listName = list
      ? `${pageLocation} | ${list.name} | ${component} | ${list.index + 1}/${
          list.totalNumberOfLists
        }`
      : ''

    const label = list
      ? `${listName} | ${index + 1}/${list.length} | ${product.id}`
      : `${pageLocation} | ${product.id} | ${variant.key}`

    trackGAEvent(
      createProductItemGAEvent({
        eventType: ProductItemEventEnum.SELECT_ITEM,
        product: { ...product, isFavourite },
        variant: variant.title,
        index,
        listName,
        label,
      }),
    )
  }
