import { DepartmentsEnum } from '@moonpig/web-shared-types-graphql/graphqlTypes'
import { BRAND_LABEL } from '@moonpig/web-core-brand/config'

type FavouriteDataType = {
  product: Product
  index: number
  total: number
  page: string
}

export type Product = {
  id: string
  title: string
  category: {
    name: string
    department: DepartmentsEnum
  }
  masterVariant: {
    price: {
      centAmount: number
      currencyCode: string
    }
  }
}

export const AddFavouriteGAEvent = ({
  product,
  index,
  total,
  page,
  isLoggedIn,
}: FavouriteDataType & { isLoggedIn?: boolean }) => {
  const gaIndex = index + 1
  const { id, category, title, masterVariant } = product
  const value = (masterVariant.price.centAmount / 100).toFixed(2)
  return {
    event: 'add_to_wishlist',
    event_data: {
      category: 'favourites',
      action: 'add to wishlist',
      label: `${page} | ${gaIndex}/${total} | ${id}`.toLowerCase(),
      non_interaction: true,
      value,
    },
    user_data: {
      is_logged_in: isLoggedIn,
    },
    ecommerce: {
      currency: masterVariant.price.currencyCode,
      value,
      items: [
        {
          index: gaIndex,
          item_brand: BRAND_LABEL.toLowerCase(),
          item_id: id,
          item_list_name: page.toLowerCase(),
          item_name: title,
          item_category: category.name,
          item_category2: category.department,
          item_category5: 'non-favourite',
          price: value,
          quantity: 1,
        },
      ],
      _clear: true,
    },
  }
}

export const RemoveFavouriteGAEvent = ({
  product,
  index,
  total,
  page,
}: FavouriteDataType) => {
  const { id, masterVariant, category, title } = product
  const gaIndex = index + 1
  const value = (masterVariant.price.centAmount / 100).toFixed(2)
  return {
    event: 'remove_from_wishlist',
    event_data: {
      category: 'favourites',
      action: 'remove from wishlist',
      label: `${page} | ${gaIndex}/${total} | ${id}`.toLowerCase(),
      non_interaction: true,
      value,
    },
    ecommerce: {
      currency: masterVariant.price.currencyCode,
      value,
      items: [
        {
          index: gaIndex,
          item_brand: BRAND_LABEL.toLowerCase(),
          item_id: id,
          item_list_name: page.toLowerCase(),
          item_name: title,
          item_category: category.name,
          item_category2: category.department,
          item_category5: 'favourite',
          price: value,
          quantity: 1,
        },
      ],
      _clear: true,
    },
  }
}
