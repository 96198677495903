import React, { FC } from 'react'
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Dot,
} from 'pure-react-carousel'
import {
  ScreenReaderOnly,
  Box,
  IconButton,
} from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import {
  IconSystemChevronLeft,
  IconSystemChevronRight,
} from '@moonpig/launchpad-assets'
import { system as s } from '@moonpig/launchpad-system'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import pluginStyles from '!!raw-loader!pure-react-carousel/dist/react-carousel.es.css' // eslint-disable-line import/no-unresolved, import/no-webpack-loader-syntax
import { useLocaleText } from './locale'

const BUTTON_WIDTH = 48

const StyledCarousel = styled(CarouselProvider)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

const StyledSliderWrapper = styled.div`
  width: 100%;
  ${s({ flex: { md: 1 } })}
`

const StyledDot = styled(Dot)`
  height: 8px;
  width: 8px;
  ${s({ borderRadius: 7, bgcolor: 'colorBlack20', mx: 3 })}

  &[disabled] {
    ${s({ bgcolor: 'colorInteractionIcon' })}
  }
`

const StyledDotsWrapper = styled.div`
  display: flex;
  flex: 0 1 100%;
  justify-content: center;
  ${s({ mt: 6 })}
`

const StyledIconButtonWrapper = styled.div`
  ${s({ display: { xs: 'none', md: 'flex' } })}
`

const StyledPluginWrapper = styled.div`
  ${pluginStyles}
`

type CarouselWrapperProps = {
  children: React.ReactNode
}

type ImageCarouselProps = {
  items: JSX.Element[]
  title: string
  imageDimensions?: {
    width: number
    height: number
  }
  initialItemIndex?: number
}

const CarouselWrapper: FC<React.PropsWithChildren<CarouselWrapperProps>> = ({
  children,
}) => <StyledPluginWrapper>{children}</StyledPluginWrapper>

export const ImageCarousel: FC<React.PropsWithChildren<ImageCarouselProps>> = ({
  items,
  title,
  imageDimensions = { width: 680, height: 680 },
  initialItemIndex = 0,
}) => {
  const t = useLocaleText()
  return (
    <Box
      maxWidth={imageDimensions && imageDimensions.width + BUTTON_WIDTH * 2}
      margin="auto"
      data-testid="lp-find-image-carousel"
    >
      <CarouselWrapper>
        <StyledCarousel
          naturalSlideWidth={imageDimensions.width}
          naturalSlideHeight={imageDimensions.height}
          totalSlides={items.length}
          lockOnWindowScroll
          key={title}
          currentSlide={initialItemIndex}
        >
          <StyledIconButtonWrapper>
            <IconButton
              icon={IconSystemChevronLeft}
              label={t('image_carousel.previous_image')}
              as={ButtonBack}
            />
          </StyledIconButtonWrapper>
          <StyledSliderWrapper>
            <Slider>
              {items.map((carouselItem, index) => (
                <Slide
                  index={index}
                  key={carouselItem.key || /* istanbul ignore next */ index}
                >
                  {carouselItem}
                </Slide>
              ))}
            </Slider>
          </StyledSliderWrapper>
          <StyledIconButtonWrapper>
            <IconButton
              icon={IconSystemChevronRight}
              label={t('image_carousel.next_image')}
              as={ButtonNext}
            />
          </StyledIconButtonWrapper>
          <StyledDotsWrapper>
            {items.map((_, key) => (
              // eslint-disable-next-line react/no-array-index-key
              <StyledDot slide={Number(key)} key={key}>
                <ScreenReaderOnly>
                  {t('image_carousel.show_image', key)}
                </ScreenReaderOnly>
              </StyledDot>
            ))}
          </StyledDotsWrapper>
        </StyledCarousel>
      </CarouselWrapper>
    </Box>
  )
}
