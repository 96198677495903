import React, { FC } from 'react'
import { useTrustpilotScript } from '../../../utils/useTrustpilotScript'

export const ProductReviews: FC<{
  id: string
}> = ({ id }) => {
  useTrustpilotScript()
  return (
    <div
      className="trustpilot-widget"
      data-locale="en-GB"
      data-template-id="5763bccae0a06d08e809ecbb"
      data-businessunit-id="4bdecbe5000064000506bce0"
      data-style-height="700px"
      data-style-width="100%"
      data-theme="light"
      data-sku={id.toUpperCase()}
      data-review-languages="en"
      data-no-reviews="show"
      data-reviewnumber="10"
      data-testid="web-browse-trustpilot-product-reviews-container"
    />
  )
}
