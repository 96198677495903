import { useEffect } from 'react'

export const TRUSTPILOT_SCRIPT_ID = 'trustpilotScript'

export function useTrustpilotScript() {
  useEffect(() => {
    const existingScript = document.getElementById(TRUSTPILOT_SCRIPT_ID)

    if (!existingScript) {
      const script = document.createElement('script') as HTMLScriptElement
      script.src =
        'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
      script.id = TRUSTPILOT_SCRIPT_ID
      script.async = true
      document.body.appendChild(script)
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.Trustpilot?.loadFromElement(
        document.getElementsByClassName('trustpilot-widget')[0],
      )
    }
  })
}
