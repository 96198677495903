import { useState } from 'react'
import { Region } from '@moonpig/web-core-types'
import { useRouter } from '@moonpig/web-core-routing'
import { useCreateEmptyDesign } from './useCreateEmptyDesign'
import { MasterVariant } from './types'
import { useGetVoucherOptions } from './useGetVoucherOptions'
import { useAddCardAndDigitalGiftToBasketMutation } from './useAddCardAndDigitalGiftToBasketMutation'
import { trackAddCardAndDigitalGiftToBasket } from './trackAddCardAndDigitalGiftToBasket'
import { Journeys } from './Journeys'

export const useAddDigitalGiftWithCardToBasket = () => {
  const { createEmptyDesign } = useCreateEmptyDesign()
  const { getVoucherOptions } = useGetVoucherOptions()
  const { addCardAndDigitalGiftToBasket } =
    useAddCardAndDigitalGiftToBasketMutation()

  const [loading, setLoading] = useState(false)
  const router = useRouter()

  const addDigitalGiftWithCardToBasket = async ({
    productId,
    digitalGiftProductMasterVariant,
    region,
    card,
    journey = Journeys.EXPERIENCE_GIFT_FIRST,
    componentName = 'unknown',
    customerId,
  }: {
    productId: string
    digitalGiftProductMasterVariant: MasterVariant
    region: Region
    customerId?: string | undefined
    card?: { id: string; sku?: string | null; variant?: string }
    journey?: Journeys
    componentName?: string
  }) => {
    setLoading(true)

    if (!digitalGiftProductMasterVariant?.sku) {
      throw new Error('Cannot add digital gift to basket without a sku')
    }

    try {
      const { id: cardId, sku: cardSku } =
        card || (await getVoucherOptions({ productId })).defaultCard

      if (!cardSku) {
        throw new Error('Cannot add card to basket without a sku')
      }

      const { design } = await createEmptyDesign({
        cardSku,
        cardId,
      })

      const result = await addCardAndDigitalGiftToBasket({
        designId: design.id,
        cardSku,
        digitalGiftSku: digitalGiftProductMasterVariant.sku,
      })

      await trackAddCardAndDigitalGiftToBasket({
        digitalGiftProductMasterVariant,
        cardSku,
        cardId,
        designId: design.id,
        totalBasketItems: result.totalItems,
        cardVariant: card?.variant,
        componentName,
        journey,
        productId,
        region,
        customerId,
      })

      router.push({
        name: 'customise-edit',
        params: {
          designId: design.id,
          journey,
          region,
          variantKey: '1',
        },
      })
    } catch {
      throw Error('We couldn’t add this to the basket, please try again')
    } finally {
      setLoading(false)
    }
  }

  return { addDigitalGiftWithCardToBasket, loading }
}
