import React, { FC, useEffect, useState } from 'react'
import { AllSystemProps } from '@moonpig/launchpad-system'
import { useExperiment } from '@moonpig/web-core-experiments'
import { useProductStore } from '../../contexts/product'
import { KlarnaInfo } from '../../components/KlarnaInfo'

export const KlarnaInfoModule: FC<AllSystemProps> = () => {
  const [
    {
      product: {
        masterVariant: {
          price: {
            centAmount: masterCentAmount,
            fractionDigits: masterFractionDigits,
            currencyCode: masterCurrencyCode,
          },
        },
      },
      variant: {
        price: {
          centAmount: selectedCentAmount,
          fractionDigits: selectedFractionDigits,
          currencyCode: selectedCurrencyCode,
        },
      },
    },
  ] = useProductStore()

  const [centAmount, setCentAmount] = useState(masterCentAmount)
  const [currencyCode, setCurrencyCode] = useState(masterCurrencyCode)
  const [fractionDigits, setFractionDigits] = useState(masterFractionDigits)

  useEffect(() => {
    setCentAmount(selectedCentAmount)
    setCurrencyCode(selectedCurrencyCode)
    setFractionDigits(selectedFractionDigits)
  }, [selectedCentAmount, selectedCurrencyCode, selectedFractionDigits])

  const isExperimentEnabled =
    useExperiment('explore-show-klarna')?.toLowerCase() === 'enabled'

  if (isExperimentEnabled && centAmount / 10 ** fractionDigits >= 20) {
    return <KlarnaInfo price={centAmount} currency={currencyCode} />
  }

  return <></>
}
