import React from 'react'
import { useLanguage, useStoreId } from '@moonpig/web-core-stores'
import { Flex, Box, Image, Text, Link } from '@moonpig/launchpad-components'
import { formatPrice } from '@moonpig/web-core-utils'
import { breakpoint, styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { IconSystemChevronRight } from '@moonpig/launchpad-assets'
import { trackGAEvent } from '@moonpig/web-core-analytics'
import { DepartmentsEnum } from '@moonpig/web-shared-types-graphql/graphqlTypes'
import { UpsellProduct } from '../../types'
import { useProductUpsellLocaleText } from './ProductUpsell.locale'

const StyledLink = styled(Link)`
  display: flex;
  width: 100%;
  align-items: center;
  text-align: left;
  overflow: hidden;
  ${s({
    bgcolor: 'colourBackground01',
    boxShadow: 3,
    borderRadius: 2,
    marginBottom: 6,
  })}

  &:hover {
    text-decoration: none;
  }
`
const IMAGE_SIZE = {
  xs: '7.5rem',
  xl: '8.5rem',
}
const StyledImage = styled(Image)`
  ${breakpoint('md')} {
    display: none;
  }
  /*
     needs to show image a bit later after "lg"
     because not enough space for dutch text
  */
  @media (min-width: 62.5em) {
    display: block;
  }
  ${s({
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
  })}
`
const StyledTextContainer = styled(Flex)`
  ${s({
    flexDirection: 'column',
    justifyContent: 'space-between',
    px: {
      xs: 6,
    },
    py: {
      xs: 6,
      xl: 8,
    },
  })}
`

const StyledIconChevronRight = styled(IconSystemChevronRight)`
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: middle;
`

const NBSP = '\u00A0'
const ALCOHOL_DEPARTMENT = 'ALCOHOL_GIFTS' as DepartmentsEnum

type QuantityOption = {
  minimum?: number
  required?: number
}

export type ProductUpsellProps = {
  upsellProduct: UpsellProduct
  selectedVariantPrice: {
    centAmount: number
    currencyCode: string
    fractionDigits: number
  }
  trackingData?: {
    pageLocation: string
    product: {
      id: string
    }
  }
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
}
const createSelectContentEnvent = ({
  trackingData,
  upsellProduct,
}: ProductUpsellProps) => ({
  content_data: {
    content_type: `${trackingData?.pageLocation} | ${trackingData?.product.id} | gift upsell`,
    item_id:
      `${upsellProduct.id} | ${upsellProduct.masterVariant.title}`.toLowerCase(),
  },
  discount_data: undefined,
  ecommerce: undefined,
  error_data: undefined,
  event: 'select_content',
  event_data: {
    category: trackingData?.pageLocation,
    action: 'gift upsell',
    label: upsellProduct.id,
    non_interaction: true,
    value: undefined,
  },
})
export const ProductUpsell: React.FC<ProductUpsellProps> = props => {
  const region = useStoreId()
  const localiseText = useProductUpsellLocaleText()
  const language = useLanguage()
  const { upsellProduct, selectedVariantPrice, onClick } = props
  const { masterVariant, slug, category, id, title } = upsellProduct
  const { price, productImages } = masterVariant
  const quantityOption: QuantityOption | null = masterVariant.quantityOption
  const minQuantity = quantityOption?.required || quantityOption?.minimum || 1
  const priceDifference =
    price.centAmount * minQuantity - selectedVariantPrice.centAmount
  const upsellText =
    upsellProduct.category.department === ALCOHOL_DEPARTMENT
      ? 'common.gift_upsell.alcohol_title'
      : 'common.gift_upsell.default_title'
  const formattedPriceDifference = formatPrice(
    priceDifference,
    selectedVariantPrice.fractionDigits,
    selectedVariantPrice.currencyCode,
    1,
    language,
  )

  const imageSrc = productImages.mainImage.medium.jpgUrl

  return (
    <StyledLink
      href={`/${region}/${category.slug}/p/${slug}/${id.toLowerCase()}/`}
      onClick={event => {
        trackGAEvent(createSelectContentEnvent(props))
        if (onClick) {
          onClick(event)
        }
      }}
    >
      <StyledImage src={imageSrc} alt={title} />
      <StyledTextContainer>
        <Text
          color="colorTextHeadline"
          typography={{ xs: 'typeMobileDisplay03', md: 'typeDesktopDisplay05' }}
        >
          {localiseText(upsellText, {
            formattedPrice: formattedPriceDifference,
          })}
        </Text>
        <Box mt={4} whiteSpace="nowrap">
          <Box as="span" whiteSpace="normal">
            {localiseText('common.gift_upsell.link')}
          </Box>
          {NBSP}
          <StyledIconChevronRight viewBox="9 5 10 18" />
        </Box>
      </StyledTextContainer>
    </StyledLink>
  )
}
