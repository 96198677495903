import { Reducer } from 'react'
import { InitialiseAction, UpdateFavouritesAction } from './types'
import type { HideToastAction, ShowToastAction, State } from './types'

export const reducer: Reducer<
  State,
  InitialiseAction | UpdateFavouritesAction | ShowToastAction | HideToastAction
> = (state, action) => {
  switch (action.type) {
    case 'UPDATE_FAVOURITES': {
      const { updatedFavourites } = action

      return {
        ...state,
        favourites: updatedFavourites,
      }
    }
    case 'SHOW_TOAST': {
      return {
        ...state,
        isToastShown: true,
      }
    }
    case 'HIDE_TOAST': {
      return {
        ...state,
        isToastShown: false,
      }
    }
    case 'INITIALISE':
      return {
        favourites: action.favourites,
        loading: false,
        location: action.location,
        isToastShown: false,
      }
    /* istanbul ignore next */
    default:
      return state
  }
}
