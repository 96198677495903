export const ProductDetailsTabClickGAEvent = (
  tabName: string,
  productId: string,
) => {
  return {
    event: 'select_content',
    content_type: `product details | ${productId} | tab | ${tabName.toLocaleLowerCase()}`,
    event_data: {
      category: 'product details',
      action: 'view item',
      label: `product details | ${productId} | tab | ${tabName.toLocaleLowerCase()}`,
      non_interaction: true,
      value: undefined,
    },
    error_data: undefined,
    ecommerce: undefined,
  }
}
