/* eslint-disable react-hooks/rules-of-hooks */
import React, { FC, useState } from 'react'
import { Box } from '@moonpig/launchpad-components'
import { useLocale } from '@moonpig/web-core-stores'
import { useStripeLoader } from '@moonpig/web-shared-payments'
import { theme } from '@moonpig/launchpad-theme'
import {
  Elements,
  PaymentMethodMessagingElement,
} from '@stripe/react-stripe-js'
import {
  StripeElementLocale,
  StripePaymentMethodMessagingElementOptions,
} from '@stripe/stripe-js'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'

type Props = {
  price: number
  currency: string
}

const StyledBox = styled(Box)`
  &.bordered-wrapper {
    ${s({
      py: 4,
      pl: 6,
      border: 1,
      borderRadius: 2,
      borderColor: 'colorBlack30',
      mb: 6,
    })};
  }
`

export const KlarnaInfo: FC<Props> = ({ price, currency, ...rest }) => {
  const { countryCode } = useLocale()
  const { stripe, error } = useStripeLoader()
  const [isMessagingReady, setIsMessagingReady] = useState(false)

  if (error) {
    return <></>
  }

  const appearance = {
    variables: {
      colorText: theme.colors.colorTextBody,
      fontSizeBase: `${theme.typography.typeBodyText.fontSize}`,
      fontFamily: `${theme.typography.typeBodyText.fontFamily}`,
    },
  }

  const elementOptions = {
    appearance,
    locale: countryCode as StripeElementLocale,
  }

  const options = {
    amount: price,
    currency,
    countryCode: countryCode.toUpperCase(),
    paymentMethodTypes: ['klarna' as const],
  } as StripePaymentMethodMessagingElementOptions

  return (
    <StyledBox
      data-testid="klarna-payment-method-messaging-wrapper"
      className={isMessagingReady ? 'bordered-wrapper' : ''}
      {...rest}
    >
      <Elements
        data-testid="klarna-payment-method-messaging-elements"
        stripe={stripe}
        options={elementOptions}
      >
        <PaymentMethodMessagingElement
          options={options}
          onReady={() => {
            setIsMessagingReady(true)
          }}
        />
      </Elements>
    </StyledBox>
  )
}
