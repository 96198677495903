import React, { FC } from 'react'
import { Box, Flex } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { useCoreLocaleText } from '@moonpig/web-core-locale-text'
import { system as s } from '@moonpig/launchpad-system'
import { useLanguage } from '@moonpig/web-core-stores'
import { formatPrice } from '@moonpig/web-core-utils'

const StyledPriceContainer = styled(Flex)`
  align-items: baseline;
  flex-wrap: wrap;
  column-gap: 8px;
`

const StyledFullPrice = styled.span`
  ${s({
    color: 'colorBlack70',
    typography: 'typeBodyCaption',
    fontWeight: 'bold',
  })};
`

const StyledDiscountDetails = styled.span`
  ${s({
    color: 'colorFeedbackSuccess',
    typography: 'typeBodyCaption',
    fontWeight: 'bold',
  })};
`

export const ProductPrice: FC<{
  priceCentAmount: number
  currencyCode: string
  fractionDigits: number
  minimumQuantity?: number
  fullPriceCentAmount?: number
  discountedPercentage?: number | null
  description?: string
}> = ({
  priceCentAmount,
  currencyCode,
  fractionDigits,
  fullPriceCentAmount,
  discountedPercentage,
  minimumQuantity,
  description,
}) => {
  const localiseText = useCoreLocaleText()
  const language = useLanguage()
  const formattedPrice = formatPrice(
    priceCentAmount,
    fractionDigits,
    currencyCode,
    minimumQuantity,
    language,
  )
  const formattedFullPrice = fullPriceCentAmount
    ? formatPrice(
        fullPriceCentAmount,
        fractionDigits,
        currencyCode,
        minimumQuantity,
        language,
      )
    : null
  const explainPrice =
    formattedFullPrice && discountedPercentage
      ? localiseText(
          'common.price_with_discount_explanation',
          formattedPrice,
          formattedFullPrice,
          discountedPercentage,
        )
      : localiseText('common.price_explanation', formattedPrice)

  return (
    <Box
      typography={{ xs: 'typeMobileDisplay04', md: 'typeDesktopDisplay05' }}
      data-testid="product-price-with-description-text"
    >
      <StyledPriceContainer
        aria-label={explainPrice}
        data-testid="product-price-container"
      >
        <span data-testid="product-price">{formattedPrice}</span>
        {formattedFullPrice && discountedPercentage && (
          <>
            <StyledFullPrice
              data-testid="product-full-price"
              style={{
                textDecoration: 'line-through',
              }}
            >
              {formattedFullPrice}
            </StyledFullPrice>
            <StyledDiscountDetails data-testid="product-discount-details">
              {localiseText('common.discount_details', discountedPercentage)}
            </StyledDiscountDetails>
          </>
        )}
      </StyledPriceContainer>
      {description ? ` ${description}` : /* istanbul ignore next */ null}
    </Box>
  )
}
