import React, { FC, useMemo, useReducer } from 'react'
import { dispatcherContext, stateContext } from './Context'
import { reducer } from './Reducer'
import { BasketDispatcher } from './types'
import { Status } from '../../components/Status'
import {
  createSetDigitalGiftAction,
  createSetSuccessAction,
  createSetErrorAction,
  createSetLoadingAction,
  createSetBasketIdAction,
  createSetLastLineItemAction,
} from './Actions'

/* istanbul ignore next */
export const BasketProvider: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    success: false,
    error: false,
    digitalGift: null,
    basketId: null,
    lastLineItem: null,
  })

  const actions = useMemo<BasketDispatcher>(
    () => ({
      setLoading: createSetLoadingAction(dispatch),
      setSuccess: createSetSuccessAction(dispatch),
      setError: createSetErrorAction(dispatch),
      setDigitalGift: createSetDigitalGiftAction(dispatch),
      setBasketId: createSetBasketIdAction(dispatch),
      setLastLineItem: createSetLastLineItemAction(dispatch),
    }),
    [],
  )

  return (
    <dispatcherContext.Provider value={actions}>
      <stateContext.Provider value={state}>
        <Status
          error={{
            status: state.error,
            onDismiss: () => actions.setError(false),
          }}
          loading={{ status: state.loading }}
        />
        {children}
      </stateContext.Provider>
    </dispatcherContext.Provider>
  )
}
