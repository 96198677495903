import { DepartmentsEnum } from '@moonpig/web-shared-types-graphql/graphqlTypes'

const cardsDepartments = new Set([
  'GREETING_CARDS',
  'INVITATIONS_AND_ANNOUNCEMENTS',
  'WEDDING_STATIONERY',
  'MINI_CARDS',
  'ALL_CARDS',
])

export const isCardProduct = (department: DepartmentsEnum) =>
  cardsDepartments.has(department)
