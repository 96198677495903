import { trackGAEvent } from '@moonpig/web-core-analytics'
import { createViewItemListGAEvent } from '../../../analytics'
import { ViewProductsListProps } from '../types'

export const createViewProductListAction =
  ({ pageLocation }: { pageLocation: string }) =>
  (payload: ViewProductsListProps) => {
    trackGAEvent(
      createViewItemListGAEvent({
        products: payload.products,
        label: `${pageLocation} | ${payload.listName} | ${payload.component}`,
      }),
    )
  }
