import { gql } from '@moonpig/web-core-graphql'
import { designFields } from './fragments'

export const GetOrCreateDraftDesignMutationGQL = gql`
  mutation GetOrCreateDraftDesignMutationNew(
    $input: GetOrCreateDraftDesignInput!
  ) {
    getOrCreateDraftDesign(input: $input) {
      __typename
      ... on Design {
        ...DesignFields
      }
      ... on DesignTemplatesServiceError {
        message
      }
      ... on DesignServiceError {
        message
      }
      ... on Error {
        message
      }
    }
  }
  ${designFields}
`
