import React, { FC } from 'react'
import { Box } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { VariantSelector } from '../../VariantSelector/VariantSelector'
import {
  VariantCategorySelector,
  VariantCategory,
} from '../VariantCategorySelector'
import { ProductQuickViewVariant } from '../types'
import {
  STANDARD_FLOWER_SIZE_ID,
  LARGE_FLOWER_SIZE_ID,
} from '../../../constants'
import { useMultiVariantSelectorLocaleText } from './MultiVariantSelector.locale'

const StyledMultiVariantSelectorWrapper = styled(Box)<{
  isProductPage?: boolean
}>`
  ${({ isProductPage }) =>
    s({
      mx: -6,
      py: 7,
      px: 6,
      bgcolor: {
        xs: isProductPage ? 'colorBlack00' : 'colorBackgroundSite',
        md: 'transparent',
      },
    })}
`

type MultiVariantSelectorProps = {
  selectedVariant?: ProductQuickViewVariant
  variants: ProductQuickViewVariant[]
  variantCategories?: VariantCategory[]
  variantsTitle?: string
  variantCategoriesTitle?: string
  onVariantChange: (variantValue: string) => void
  variantInfoLink?: string
  variantInfoLinkText?: string
  id: string
  isLandscape: boolean
  isProductPage?: boolean
}

type FlowerVariantSize =
  | typeof STANDARD_FLOWER_SIZE_ID
  | typeof LARGE_FLOWER_SIZE_ID

const isFlowerVariant = (sizeId: string): sizeId is FlowerVariantSize => {
  return [STANDARD_FLOWER_SIZE_ID, LARGE_FLOWER_SIZE_ID].includes(sizeId)
}

export const MultiVariantSelector: FC<MultiVariantSelectorProps> = ({
  selectedVariant,
  variants,
  variantCategories,
  variantsTitle,
  variantCategoriesTitle,
  onVariantChange,
  variantInfoLink,
  variantInfoLinkText,
  id,
  isLandscape,
  isProductPage,
}) => {
  const localiseText = useMultiVariantSelectorLocaleText()
  return (
    <StyledMultiVariantSelectorWrapper isProductPage={isProductPage}>
      {variantCategories ? (
        <VariantCategorySelector
          variantCategories={variantCategories}
          categoriesTitle={variantCategoriesTitle}
          variantsTitle={variantsTitle}
          onChange={onVariantChange}
          variantInfoLink={variantInfoLink}
          variantInfoLinkText={variantInfoLinkText}
        />
      ) : (
        <Box>
          <VariantSelector
            key={id}
            title={variantsTitle}
            isLandscape={isLandscape}
            selectedValue={selectedVariant?.value || variants[0].value}
            items={variants.map(variant => {
              const { price } = variant
              return {
                ...variant,
                description: isFlowerVariant(variant.value)
                  ? localiseText(
                      `common.flower_variant_description_${variant.value}`,
                    )
                  : variant.description,
                price: `${price.currencySymbol}${price.displayPrice}`,
              }
            })}
            onChange={onVariantChange}
          />
        </Box>
      )}
    </StyledMultiVariantSelectorWrapper>
  )
}
