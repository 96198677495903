import { useMemo, useState } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { logger } from '@moonpig/web-core-monitoring'

export const useStripeLoader = () => {
  const [error, setError] = useState<string | null>(null)

  const loadStripeWithRetry = async () => {
    const key = process.env.STRIPE_PUBLISHABLE_KEY
    if (!key) {
      setError('STRIPE_PUBLISHABLE_KEY is undefined')
      logger.fixImmediately('STRIPE_PUBLISHABLE_KEY is undefined')
      return null
    }

    const config = {
      betas: ['blocked_card_brands_beta_2'],
    }

    try {
      return await loadStripe(key, config)
    } catch (firstError) {
      logger.warning(
        'Unable to load Stripe on first attempt. Retrying...',
        firstError,
      )
      try {
        return await loadStripe(key, config)
      } catch (secondError) {
        logger.fixToday('Failed to load Stripe on second attempt.', secondError)
        setError('Failed to load Stripe')
        return null
      }
    }
  }

  const stripePromise = useMemo(() => loadStripeWithRetry(), [])

  return { stripe: stripePromise, error }
}
