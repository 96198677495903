import { createLocaleTextHook } from '@moonpig/web-core-locale-text'

const dictionary = {
  'from_price.copy': {
    'en-GB': 'From',
    'nl-NL': 'Vanaf',
  },
}

export const useLocaleText = createLocaleTextHook({
  dictionary,
})
