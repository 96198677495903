export const createProductCTAImpressionGAEvent = (
  requiresEditing: boolean | undefined,
  productId: string,
) => {
  const ctaType = requiresEditing
    ? 'personalisation only'
    : 'personalisation and add to basket'

  return {
    event: 'impression',
    content_data: {
      content_type: `product | view cta | primary button | ${ctaType}`,
      item_id: `skip editor experiment enabled | ${productId}`,
    },
    // For GA3 backward compatibility
    event_data: {
      category: 'product',
      action: 'view cta',
      label: `primary button | ${ctaType} | skip editor experiment enabled | ${productId}`,
      non_interaction: true,
      value: undefined,
    },
    discount_data: undefined,
    error_data: undefined,
    ecommerce: undefined,
  }
}
