import { createContext, useContext } from 'react'
import { TrackingDispatcher } from './types'

export const dispatcherContext = createContext<TrackingDispatcher | null>(null)

export const useTracking = (): TrackingDispatcher => {
  const dispatcher = useContext(dispatcherContext)

  /* istanbul ignore if */
  if (!dispatcher) {
    throw new Error('TrackingProvider is missing.')
  }

  return dispatcher
}
