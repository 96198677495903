import { createLocaleTextHook } from '@moonpig/web-core-locale-text'

const dictionary = {
  'image_carousel.previous_image': {
    'en-GB': 'Previous image',
    'nl-NL': 'Vorige afbeelding',
  },
  'image_carousel.next_image': {
    'en-GB': 'Next image',
    'nl-NL': 'Volgende afbeelding',
  },
  'image_carousel.show_image': {
    'en-GB': (key: number) => `Show image ${key + 1}`,
    'nl-NL': /* istanbul ignore next */ (key: number) =>
      `Afbeelding ${key + 1} weergeven`,
  },
}

export const useLocaleText = createLocaleTextHook({
  dictionary,
})
