import { Dispatch } from 'react'
import {
  ActionSetDigitalGift,
  ActionSetSuccess,
  ActionSetError,
  ActionSetLoading,
  ActionSetBasketId,
  ActionSetLastLineItem,
} from './types'
import { ProductInfoProduct } from '../../types'
import { LineItem } from '../../utils/addDigitalGiftToBasket/types'

export const createSetLoadingAction =
  (dispatch: Dispatch<ActionSetLoading>) => (loading: boolean) => {
    dispatch({ type: 'SET_LOADING', payload: loading })
  }

export const createSetSuccessAction =
  (dispatch: Dispatch<ActionSetSuccess>) => (success: boolean) => {
    dispatch({ type: 'SET_SUCCESS', payload: success })
  }

export const createSetErrorAction =
  (dispatch: Dispatch<ActionSetError>) => (error: boolean) => {
    dispatch({ type: 'SET_ERROR', payload: error })
  }

export const createSetDigitalGiftAction =
  (dispatch: Dispatch<ActionSetDigitalGift>) =>
  (digitalGift: ProductInfoProduct | null) => {
    dispatch({ type: 'SET_DIGITAL_GIFT', payload: digitalGift })
  }

export const createSetBasketIdAction =
  (dispatch: Dispatch<ActionSetBasketId>) => (basketId: string | null) => {
    dispatch({ type: 'SET_BASKET_ID', payload: basketId })
  }

export const createSetLastLineItemAction =
  (dispatch: Dispatch<ActionSetLastLineItem>) =>
  (lastLineItem: LineItem | null) => {
    dispatch({ type: 'SET_LAST_LINE_ITEM', payload: lastLineItem })
  }
