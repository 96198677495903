type ImpressionEventProps = {
  category: string
  action: string
  label: string
  item_id: string
}

export const createImpressionGAEvent = ({
  category,
  action,
  label,
  item_id,
}: ImpressionEventProps) => ({
  event: 'impression',
  content_data: {
    content_type: `${category} | ${action} | ${label}`,
    item_id,
  },
  event_data: {
    category,
    action,
    label,
    non_interaction: true,
  },
})
