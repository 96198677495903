import { gql } from '@moonpig/web-core-graphql'

export const GetFavouritesListGQL = gql`
  query GetFavourites {
    me {
      customer {
        favourites {
          list {
            id
            name
            products {
              id
            }
          }
        }
      }
    }
  }
`
