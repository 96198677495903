import { Dispatch } from 'react'
import { ProductInfoProduct } from '../../types'
import {
  ActionReset,
  ActionSelectQuantity,
  ActionSelectVariant,
  ActionSelectAddon,
  ActionSetProduct,
  ActionSetIsGroupCardSelected,
} from './types'
import { AddonsFieldsFragment } from '../../queries/useProductWithAddonsQuery'

export const createSelectVariantAction =
  (dispatch: Dispatch<ActionSelectVariant>) => (variantKey: string) => {
    dispatch({ type: 'SELECT_VARIANT', payload: variantKey })
  }

export const createSetProductAction =
  (dispatch: Dispatch<ActionSetProduct>) => (product: ProductInfoProduct) => {
    dispatch({ type: 'SET_PRODUCT', payload: product })
  }

export const createSelectQuantityAction =
  (dispatch: Dispatch<ActionSelectQuantity>) => (quantity: number) => {
    dispatch({ type: 'SELECT_QUANTITY', payload: quantity })
  }

export const createSelectAddonAction =
  (dispatch: Dispatch<ActionSelectAddon>) =>
  (addon: AddonsFieldsFragment | null) => {
    dispatch({ type: 'SELECT_ADDON', payload: addon })
  }

export const createSetIsGroupCardSelectedAction =
  (dispatch: Dispatch<ActionSetIsGroupCardSelected>) =>
  (isGroupCardSelected: boolean) => {
    dispatch({
      type: 'SET_IS_GROUP_CARD_SELECTED',
      payload: isGroupCardSelected,
    })
  }

export const createResetAction = (dispatch: Dispatch<ActionReset>) => () => {
  dispatch({ type: 'RESET' })
}
