import React, { FC } from 'react'
import {
  Box,
  Text,
  Image,
  Flex,
  SecondaryButton,
} from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { useLocaleText } from './MultiAddOnSelector.locale'
import { AddonsFieldsFragment } from '../../queries/useProductWithAddonsQuery'

type SingleAddonDetailsProps = {
  addon: AddonsFieldsFragment
  onAddToBasket: (addon: AddonsFieldsFragment) => void
}

const StyledImage = styled(Image)`
  ${s({
    width: { xs: '8.3125rem', lg: '10rem' },
    borderRadius: 2,
  })}
`

export const SingleAddonDetails: FC<SingleAddonDetailsProps> = ({
  addon,
  onAddToBasket,
}) => {
  const {
    title,
    price,
    image: { url },
  } = addon

  const localiseText = useLocaleText()

  return (
    <Flex width="100%" alignItems="center">
      <StyledImage src={url} />
      <Box pl={{ xs: 6, lg: 8 }} width="100%">
        <Text
          typography={{
            xs: 'typeMobileDisplay03',
            md: 'typeDesktopDisplay05',
          }}
          display="block"
        >
          {title}
        </Text>
        <Text typography="typeBodyLabel" display="block" mt={4}>
          {price.display}
        </Text>
        <SecondaryButton
          type="button"
          mt={4}
          width="100%"
          aria-label={localiseText('multi_addons.accessibility.add_this_item', {
            title,
          })}
          onClick={() => onAddToBasket(addon)}
        >
          {localiseText('multi_addons.add_this_item')}
        </SecondaryButton>
      </Box>
    </Flex>
  )
}
