// To update use:
// https://codesandbox.io/s/fervent-dust-mtzkks";

type HolidaysData = {
  gb: string[]
  us: string[]
  au: string[]
  ie: string[]
  nl: string[]
}

export const holidaysData: HolidaysData = {
  gb: [
    '2023-01-01',
    '2023-01-02',
    '2023-03-19',
    '2023-04-07',
    '2023-04-09',
    '2023-04-10',
    '2023-05-01',
    '2023-05-08',
    '2023-05-29',
    '2023-06-18',
    '2023-12-25',
    '2023-12-26',
    '2024-01-01',
    '2024-03-10',
    '2024-03-29',
    '2024-03-31',
    '2024-04-01',
    '2024-05-06',
    '2024-05-27',
    '2024-06-16',
    '2024-12-25',
    '2024-12-26',
    '2025-01-01',
    '2025-03-30',
    '2025-04-18',
    '2025-04-20',
    '2025-04-21',
    '2025-05-05',
    '2025-05-26',
    '2025-06-15',
    '2025-12-25',
    '2025-12-26',
    '2026-01-01',
    '2026-03-15',
    '2026-04-03',
    '2026-04-05',
    '2026-04-06',
    '2026-05-04',
    '2026-05-25',
    '2026-06-21',
    '2026-12-25',
    '2026-12-26',
    '2026-12-28',
    '2027-01-01',
    '2027-03-07',
    '2027-03-26',
    '2027-03-28',
    '2027-03-29',
    '2027-05-03',
    '2027-05-31',
    '2027-06-20',
    '2027-12-25',
    '2027-12-26',
    '2027-12-27',
    '2027-12-28',
    '2028-01-01',
    '2028-01-03',
    '2028-03-26',
    '2028-04-14',
    '2028-04-16',
    '2028-04-17',
    '2028-05-01',
    '2028-05-29',
    '2028-06-18',
    '2028-12-25',
    '2028-12-26',
    '2029-01-01',
    '2029-03-11',
    '2029-03-30',
    '2029-04-01',
    '2029-04-02',
    '2029-05-07',
    '2029-05-28',
    '2029-06-17',
    '2029-12-25',
    '2029-12-26',
    '2030-01-01',
    '2030-03-31',
    '2030-04-19',
    '2030-04-21',
    '2030-04-22',
    '2030-05-06',
    '2030-05-27',
    '2030-06-16',
    '2030-12-25',
    '2030-12-26',
    '2031-01-01',
    '2031-03-23',
    '2031-04-11',
    '2031-04-13',
    '2031-04-14',
    '2031-05-05',
    '2031-05-26',
    '2031-06-15',
    '2031-12-25',
    '2031-12-26',
    '2032-01-01',
    '2032-03-07',
    '2032-03-26',
    '2032-03-28',
    '2032-03-29',
    '2032-05-03',
    '2032-05-31',
    '2032-06-20',
    '2032-12-25',
    '2032-12-26',
    '2032-12-27',
    '2032-12-28',
    '2033-01-01',
    '2033-01-03',
    '2033-03-27',
    '2033-04-15',
    '2033-04-17',
    '2033-04-18',
    '2033-05-02',
    '2033-05-30',
    '2033-06-19',
    '2033-12-25',
    '2033-12-26',
    '2033-12-27',
  ],
  us: [
    '2023-01-01',
    '2023-01-02',
    '2023-01-16',
    '2023-02-14',
    '2023-02-20',
    '2023-03-17',
    '2023-04-09',
    '2023-04-18',
    '2023-04-26',
    '2023-05-14',
    '2023-05-29',
    '2023-06-18',
    '2023-06-19',
    '2023-07-04',
    '2023-09-04',
    '2023-10-09',
    '2023-10-31',
    '2023-11-10',
    '2023-11-11',
    '2023-11-23',
    '2023-11-24',
    '2023-12-24',
    '2023-12-25',
    '2023-12-31',
    '2024-01-01',
    '2024-01-15',
    '2024-02-14',
    '2024-02-19',
    '2024-03-17',
    '2024-03-31',
    '2024-04-15',
    '2024-04-24',
    '2024-05-12',
    '2024-05-27',
    '2024-06-16',
    '2024-06-19',
    '2024-07-04',
    '2024-09-02',
    '2024-10-14',
    '2024-10-31',
    '2024-11-05',
    '2024-11-11',
    '2024-11-28',
    '2024-11-29',
    '2024-12-24',
    '2024-12-25',
    '2024-12-31',
    '2025-01-01',
    '2025-01-20',
    '2025-02-14',
    '2025-02-17',
    '2025-03-17',
    '2025-04-15',
    '2025-04-20',
    '2025-04-23',
    '2025-05-11',
    '2025-05-26',
    '2025-06-15',
    '2025-06-19',
    '2025-07-04',
    '2025-09-01',
    '2025-10-13',
    '2025-10-31',
    '2025-11-11',
    '2025-11-27',
    '2025-11-28',
    '2025-12-24',
    '2025-12-25',
    '2025-12-31',
    '2026-01-01',
    '2026-01-19',
    '2026-02-14',
    '2026-02-16',
    '2026-03-17',
    '2026-04-05',
    '2026-04-15',
    '2026-04-22',
    '2026-05-10',
    '2026-05-25',
    '2026-06-19',
    '2026-06-21',
    '2026-07-03',
    '2026-07-04',
    '2026-09-07',
    '2026-10-12',
    '2026-10-31',
    '2026-11-11',
    '2026-11-26',
    '2026-11-27',
    '2026-12-24',
    '2026-12-25',
    '2026-12-31',
    '2027-01-01',
    '2027-01-18',
    '2027-02-14',
    '2027-02-15',
    '2027-03-17',
    '2027-03-28',
    '2027-04-15',
    '2027-04-21',
    '2027-05-09',
    '2027-05-31',
    '2027-06-18',
    '2027-06-19',
    '2027-06-20',
    '2027-07-04',
    '2027-07-05',
    '2027-09-06',
    '2027-10-11',
    '2027-10-31',
    '2027-11-11',
    '2027-11-25',
    '2027-11-26',
    '2027-12-24',
    '2027-12-24',
    '2027-12-25',
    '2027-12-31',
    '2027-12-31',
    '2028-01-01',
    '2028-01-17',
    '2028-02-14',
    '2028-02-21',
    '2028-03-17',
    '2028-04-16',
    '2028-04-18',
    '2028-04-26',
    '2028-05-14',
    '2028-05-29',
    '2028-06-18',
    '2028-06-19',
    '2028-07-04',
    '2028-09-04',
    '2028-10-09',
    '2028-10-31',
    '2028-11-07',
    '2028-11-10',
    '2028-11-11',
    '2028-11-23',
    '2028-11-24',
    '2028-12-24',
    '2028-12-25',
    '2028-12-31',
    '2029-01-01',
    '2029-01-15',
    '2029-02-14',
    '2029-02-19',
    '2029-03-17',
    '2029-04-01',
    '2029-04-17',
    '2029-04-25',
    '2029-05-13',
    '2029-05-28',
    '2029-06-17',
    '2029-06-19',
    '2029-07-04',
    '2029-09-03',
    '2029-10-08',
    '2029-10-31',
    '2029-11-11',
    '2029-11-12',
    '2029-11-22',
    '2029-11-23',
    '2029-12-24',
    '2029-12-25',
    '2029-12-31',
    '2030-01-01',
    '2030-01-21',
    '2030-02-14',
    '2030-02-18',
    '2030-03-17',
    '2030-04-15',
    '2030-04-21',
    '2030-04-24',
    '2030-05-12',
    '2030-05-27',
    '2030-06-16',
    '2030-06-19',
    '2030-07-04',
    '2030-09-02',
    '2030-10-14',
    '2030-10-31',
    '2030-11-11',
    '2030-11-28',
    '2030-11-29',
    '2030-12-24',
    '2030-12-25',
    '2030-12-31',
    '2031-01-01',
    '2031-01-20',
    '2031-02-14',
    '2031-02-17',
    '2031-03-17',
    '2031-04-13',
    '2031-04-15',
    '2031-04-23',
    '2031-05-11',
    '2031-05-26',
    '2031-06-15',
    '2031-06-19',
    '2031-07-04',
    '2031-09-01',
    '2031-10-13',
    '2031-10-31',
    '2031-11-11',
    '2031-11-27',
    '2031-11-28',
    '2031-12-24',
    '2031-12-25',
    '2031-12-31',
    '2032-01-01',
    '2032-01-19',
    '2032-02-14',
    '2032-02-16',
    '2032-03-17',
    '2032-03-28',
    '2032-04-15',
    '2032-04-21',
    '2032-05-09',
    '2032-05-31',
    '2032-06-18',
    '2032-06-19',
    '2032-06-20',
    '2032-07-04',
    '2032-07-05',
    '2032-09-06',
    '2032-10-11',
    '2032-10-31',
    '2032-11-02',
    '2032-11-11',
    '2032-11-25',
    '2032-11-26',
    '2032-12-24',
    '2032-12-24',
    '2032-12-25',
    '2032-12-31',
    '2032-12-31',
    '2033-01-01',
    '2033-01-17',
    '2033-02-14',
    '2033-02-21',
    '2033-03-17',
    '2033-04-17',
    '2033-04-18',
    '2033-04-27',
    '2033-05-08',
    '2033-05-30',
    '2033-06-19',
    '2033-06-19',
    '2033-06-20',
    '2033-07-04',
    '2033-09-05',
    '2033-10-10',
    '2033-10-31',
    '2033-11-11',
    '2033-11-24',
    '2033-11-25',
    '2033-12-24',
    '2033-12-25',
    '2033-12-26',
    '2033-12-31',
  ],
  au: [
    '2023-01-01',
    '2023-01-02',
    '2023-01-26',
    '2023-04-07',
    '2023-04-08',
    '2023-04-09',
    '2023-04-10',
    '2023-04-25',
    '2023-05-14',
    '2023-09-03',
    '2023-12-25',
    '2023-12-26',
    '2024-01-01',
    '2024-01-26',
    '2024-03-29',
    '2024-03-30',
    '2024-03-31',
    '2024-04-01',
    '2024-04-25',
    '2024-05-12',
    '2024-09-01',
    '2024-12-25',
    '2024-12-26',
    '2025-01-01',
    '2025-01-27',
    '2025-04-18',
    '2025-04-19',
    '2025-04-20',
    '2025-04-21',
    '2025-04-25',
    '2025-05-11',
    '2025-09-07',
    '2025-12-25',
    '2025-12-26',
    '2026-01-01',
    '2026-01-26',
    '2026-04-03',
    '2026-04-04',
    '2026-04-05',
    '2026-04-06',
    '2026-04-25',
    '2026-05-10',
    '2026-09-06',
    '2026-12-25',
    '2026-12-26',
    '2026-12-28',
    '2027-01-01',
    '2027-01-26',
    '2027-03-26',
    '2027-03-27',
    '2027-03-28',
    '2027-03-29',
    '2027-04-25',
    '2027-05-09',
    '2027-09-05',
    '2027-12-25',
    '2027-12-26',
    '2027-12-27',
    '2027-12-28',
    '2028-01-01',
    '2028-01-03',
    '2028-01-26',
    '2028-04-14',
    '2028-04-15',
    '2028-04-16',
    '2028-04-17',
    '2028-04-25',
    '2028-05-14',
    '2028-09-03',
    '2028-12-25',
    '2028-12-26',
    '2029-01-01',
    '2029-01-26',
    '2029-03-30',
    '2029-03-31',
    '2029-04-01',
    '2029-04-02',
    '2029-04-25',
    '2029-05-13',
    '2029-09-02',
    '2029-12-25',
    '2029-12-26',
    '2030-01-01',
    '2030-01-28',
    '2030-04-19',
    '2030-04-20',
    '2030-04-21',
    '2030-04-22',
    '2030-04-25',
    '2030-05-12',
    '2030-09-01',
    '2030-12-25',
    '2030-12-26',
    '2031-01-01',
    '2031-01-27',
    '2031-04-11',
    '2031-04-12',
    '2031-04-13',
    '2031-04-14',
    '2031-04-25',
    '2031-05-11',
    '2031-09-07',
    '2031-12-25',
    '2031-12-26',
    '2032-01-01',
    '2032-01-26',
    '2032-03-26',
    '2032-03-27',
    '2032-03-28',
    '2032-03-29',
    '2032-04-25',
    '2032-05-09',
    '2032-09-05',
    '2032-12-25',
    '2032-12-26',
    '2032-12-27',
    '2032-12-28',
    '2033-01-01',
    '2033-01-03',
    '2033-01-26',
    '2033-04-15',
    '2033-04-16',
    '2033-04-17',
    '2033-04-18',
    '2033-04-25',
    '2033-05-08',
    '2033-09-04',
    '2033-12-25',
    '2033-12-26',
    '2033-12-27',
  ],
  ie: [
    '2023-01-01',
    '2023-01-02',
    '2023-03-17',
    '2023-03-19',
    '2023-04-07',
    '2023-04-09',
    '2023-04-10',
    '2023-05-01',
    '2023-06-05',
    '2023-06-18',
    '2023-08-07',
    '2023-10-30',
    '2023-12-25',
    '2023-12-26',
    '2023-12-27',
    '2024-01-01',
    '2024-01-01',
    '2024-03-10',
    '2024-03-17',
    '2024-03-18',
    '2024-03-29',
    '2024-03-31',
    '2024-04-01',
    '2024-05-06',
    '2024-06-03',
    '2024-06-16',
    '2024-08-05',
    '2024-10-28',
    '2024-12-25',
    '2024-12-26',
    '2024-12-27',
    '2025-01-01',
    '2025-01-06',
    '2025-03-17',
    '2025-03-30',
    '2025-04-18',
    '2025-04-20',
    '2025-04-21',
    '2025-05-05',
    '2025-06-02',
    '2025-06-15',
    '2025-08-04',
    '2025-10-27',
    '2025-12-25',
    '2025-12-26',
    '2025-12-27',
    '2025-12-29',
    '2026-01-01',
    '2026-01-05',
    '2026-03-15',
    '2026-03-17',
    '2026-04-03',
    '2026-04-05',
    '2026-04-06',
    '2026-05-04',
    '2026-06-01',
    '2026-06-21',
    '2026-08-03',
    '2026-10-26',
    '2026-12-25',
    '2026-12-26',
    '2026-12-27',
    '2026-12-28',
    '2026-12-29',
    '2027-01-01',
    '2027-01-04',
    '2027-03-07',
    '2027-03-17',
    '2027-03-26',
    '2027-03-28',
    '2027-03-29',
    '2027-05-03',
    '2027-06-07',
    '2027-06-20',
    '2027-08-02',
    '2027-10-25',
    '2027-12-25',
    '2027-12-26',
    '2027-12-27',
    '2027-12-27',
    '2028-01-01',
    '2028-01-03',
    '2028-03-17',
    '2028-03-26',
    '2028-04-14',
    '2028-04-16',
    '2028-04-17',
    '2028-05-01',
    '2028-06-05',
    '2028-06-18',
    '2028-08-07',
    '2028-10-30',
    '2028-12-25',
    '2028-12-26',
    '2028-12-27',
    '2029-01-01',
    '2029-01-01',
    '2029-03-11',
    '2029-03-17',
    '2029-03-19',
    '2029-03-30',
    '2029-04-01',
    '2029-04-02',
    '2029-05-07',
    '2029-06-04',
    '2029-06-17',
    '2029-08-06',
    '2029-10-29',
    '2029-12-25',
    '2029-12-26',
    '2029-12-27',
    '2030-01-01',
    '2030-01-07',
    '2030-03-17',
    '2030-03-18',
    '2030-03-31',
    '2030-04-19',
    '2030-04-21',
    '2030-04-22',
    '2030-05-06',
    '2030-06-03',
    '2030-06-16',
    '2030-08-05',
    '2030-10-28',
    '2030-12-25',
    '2030-12-26',
    '2030-12-27',
    '2031-01-01',
    '2031-01-06',
    '2031-03-17',
    '2031-03-23',
    '2031-04-11',
    '2031-04-13',
    '2031-04-14',
    '2031-05-05',
    '2031-06-02',
    '2031-06-15',
    '2031-08-04',
    '2031-10-27',
    '2031-12-25',
    '2031-12-26',
    '2031-12-27',
    '2031-12-29',
    '2032-01-01',
    '2032-01-05',
    '2032-03-07',
    '2032-03-17',
    '2032-03-26',
    '2032-03-28',
    '2032-03-29',
    '2032-05-03',
    '2032-06-07',
    '2032-06-20',
    '2032-08-02',
    '2032-10-25',
    '2032-12-25',
    '2032-12-26',
    '2032-12-27',
    '2032-12-27',
    '2033-01-01',
    '2033-01-03',
    '2033-03-17',
    '2033-03-27',
    '2033-04-15',
    '2033-04-17',
    '2033-04-18',
    '2033-05-02',
    '2033-06-06',
    '2033-06-19',
    '2033-08-01',
    '2033-10-31',
    '2033-12-25',
    '2033-12-26',
    '2033-12-27',
  ],
  nl: [
    '2023-01-01',
    '2023-04-07',
    '2023-04-09',
    '2023-04-10',
    '2023-04-27',
    '2023-05-04',
    '2023-05-05',
    '2023-05-14',
    '2023-05-18',
    '2023-05-28',
    '2023-05-29',
    '2023-06-18',
    '2023-09-19',
    '2023-11-11',
    '2023-12-05',
    '2023-12-15',
    '2023-12-25',
    '2023-12-26',
    '2023-12-31',
    '2024-01-01',
    '2024-03-29',
    '2024-03-31',
    '2024-04-01',
    '2024-04-27',
    '2024-05-04',
    '2024-05-05',
    '2024-05-09',
    '2024-05-12',
    '2024-05-19',
    '2024-05-20',
    '2024-06-16',
    '2024-09-17',
    '2024-11-11',
    '2024-12-05',
    '2024-12-15',
    '2024-12-25',
    '2024-12-26',
    '2024-12-31',
    '2025-01-01',
    '2025-04-18',
    '2025-04-20',
    '2025-04-21',
    '2025-04-26',
    '2025-05-04',
    '2025-05-05',
    '2025-05-11',
    '2025-05-29',
    '2025-06-08',
    '2025-06-09',
    '2025-06-15',
    '2025-09-16',
    '2025-11-11',
    '2025-12-05',
    '2025-12-15',
    '2025-12-25',
    '2025-12-26',
    '2025-12-31',
    '2026-01-01',
    '2026-04-03',
    '2026-04-05',
    '2026-04-06',
    '2026-04-27',
    '2026-05-04',
    '2026-05-05',
    '2026-05-10',
    '2026-05-14',
    '2026-05-24',
    '2026-05-25',
    '2026-06-21',
    '2026-09-15',
    '2026-11-11',
    '2026-12-05',
    '2026-12-15',
    '2026-12-25',
    '2026-12-26',
    '2026-12-31',
    '2027-01-01',
    '2027-03-26',
    '2027-03-28',
    '2027-03-29',
    '2027-04-27',
    '2027-05-04',
    '2027-05-05',
    '2027-05-06',
    '2027-05-09',
    '2027-05-16',
    '2027-05-17',
    '2027-06-20',
    '2027-09-21',
    '2027-11-11',
    '2027-12-05',
    '2027-12-15',
    '2027-12-25',
    '2027-12-26',
    '2027-12-31',
    '2028-01-01',
    '2028-04-14',
    '2028-04-16',
    '2028-04-17',
    '2028-04-27',
    '2028-05-04',
    '2028-05-05',
    '2028-05-14',
    '2028-05-25',
    '2028-06-04',
    '2028-06-05',
    '2028-06-18',
    '2028-09-19',
    '2028-11-11',
    '2028-12-05',
    '2028-12-15',
    '2028-12-25',
    '2028-12-26',
    '2028-12-31',
    '2029-01-01',
    '2029-03-30',
    '2029-04-01',
    '2029-04-02',
    '2029-04-27',
    '2029-05-04',
    '2029-05-05',
    '2029-05-10',
    '2029-05-13',
    '2029-05-20',
    '2029-05-21',
    '2029-06-17',
    '2029-09-18',
    '2029-11-11',
    '2029-12-05',
    '2029-12-15',
    '2029-12-25',
    '2029-12-26',
    '2029-12-31',
    '2030-01-01',
    '2030-04-19',
    '2030-04-21',
    '2030-04-22',
    '2030-04-27',
    '2030-05-04',
    '2030-05-05',
    '2030-05-12',
    '2030-05-30',
    '2030-06-09',
    '2030-06-10',
    '2030-06-16',
    '2030-09-17',
    '2030-11-11',
    '2030-12-05',
    '2030-12-15',
    '2030-12-25',
    '2030-12-26',
    '2030-12-31',
    '2031-01-01',
    '2031-04-11',
    '2031-04-13',
    '2031-04-14',
    '2031-04-26',
    '2031-05-04',
    '2031-05-05',
    '2031-05-11',
    '2031-05-22',
    '2031-06-01',
    '2031-06-02',
    '2031-06-15',
    '2031-09-16',
    '2031-11-11',
    '2031-12-05',
    '2031-12-15',
    '2031-12-25',
    '2031-12-26',
    '2031-12-31',
    '2032-01-01',
    '2032-03-26',
    '2032-03-28',
    '2032-03-29',
    '2032-04-27',
    '2032-05-04',
    '2032-05-05',
    '2032-05-06',
    '2032-05-09',
    '2032-05-16',
    '2032-05-17',
    '2032-06-20',
    '2032-09-21',
    '2032-11-11',
    '2032-12-05',
    '2032-12-15',
    '2032-12-25',
    '2032-12-26',
    '2032-12-31',
    '2033-01-01',
    '2033-04-15',
    '2033-04-17',
    '2033-04-18',
    '2033-04-27',
    '2033-05-04',
    '2033-05-05',
    '2033-05-08',
    '2033-05-26',
    '2033-06-05',
    '2033-06-06',
    '2033-06-19',
    '2033-09-20',
    '2033-11-11',
    '2033-12-05',
    '2033-12-15',
    '2033-12-25',
    '2033-12-26',
    '2033-12-31',
  ],
}
