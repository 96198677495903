import { useMutation } from '@moonpig/web-core-graphql'
import { logger } from '@moonpig/web-core-monitoring'
import {
  RemoveFromFavouritesListMutation,
  RemoveFromFavouritesListMutationVariables,
  RemoveFromFavouritesListGQL,
  RemoveFromFavouritesList_removeFromFavouritesList_products,
} from '@moonpig/web-shared-favourites'

export function useRemoveFromFavouritesList() {
  const [removeFromFavouriteListMutation] = useMutation<
    RemoveFromFavouritesListMutation,
    RemoveFromFavouritesListMutationVariables
  >(RemoveFromFavouritesListGQL)

  const removeFromFavouritesList = (productId: string) => {
    return removeFromFavouriteListMutation({
      variables: { productIds: [productId] },
    })
      .then(({ data }) => {
        const products = data?.removeFromFavouritesList.products.filter(Boolean)

        return Promise.resolve(
          products as RemoveFromFavouritesList_removeFromFavouritesList_products[],
        )
      })
      .catch(error => {
        logger.fixToday(
          'Favourites - Failed to remove product from favourites',
          {
            productId,
            data: JSON.stringify(error),
          },
        )
      })
  }

  return { removeFromFavouritesList }
}
