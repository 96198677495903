import { useApolloClient } from '@moonpig/web-core-graphql'
import {
  GetDigitalGiftDefaultCardQuery,
  GetDigitalGiftDefaultCardQueryVariables,
} from '../../queries/addToBasket/__generated__/getDigitalGiftDefaultCard'
import { GetDigitalGiftDefaultCardGQL } from '../../queries/addToBasket/getDigitalGiftDefaultCard'

export function useGetVoucherOptions() {
  const client = useApolloClient()

  const getVoucherOptions = async ({ productId }: { productId: string }) => {
    const { data } = await client.query<
      GetDigitalGiftDefaultCardQuery,
      GetDigitalGiftDefaultCardQueryVariables
    >({
      variables: {
        productId,
      },
      query: GetDigitalGiftDefaultCardGQL,
    })

    if (data?.productLookup.products.length > 1) {
      throw new Error('Product lookup returned more than one result')
    }

    if (!data?.productLookup.products.length) {
      throw new Error('Could not find the digital gift product')
    }

    if (!data?.productLookup?.products[0].voucherOptions) {
      throw new Error(
        'Could not find voucher options for the digital gift product',
      )
    }

    if (
      !data?.productLookup?.products[0].voucherOptions?.defaultCard
        .masterVariant.sku
    ) {
      throw new Error('Could not find the default card product sku')
    }

    return {
      defaultCard: {
        id: data.productLookup.products[0].voucherOptions.defaultCard.id,
        sku: data.productLookup.products[0].voucherOptions.defaultCard
          .masterVariant.sku,
      },
    }
  }

  return {
    getVoucherOptions,
  }
}
