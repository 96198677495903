import {
  createGenericSelectContentEvent,
  trackGAEvent,
} from '@moonpig/web-core-analytics'
import { ProductInfoProduct } from '../../../types'
import { SelectContentProps } from '../types'

type Args = {
  product: ProductInfoProduct
  pageLocation: string
}

export const createSelectContentAction =
  ({ product, pageLocation }: Args) =>
  (payload: SelectContentProps) => {
    trackGAEvent(
      createGenericSelectContentEvent({
        eventCategory: pageLocation,
        eventAction: payload.action,
        eventLabel: `${payload.component} | ${payload.label}`,
        itemId: product.id,
      }),
    )
  }
