import {
  E_CARD_SIZE_ID,
  GIANT_CARD_SIZE_ID,
  GIANT_SQUARE_CARD_SIZE_ID,
  LARGE_CARD_SIZE_ID,
  LARGE_SQUARE_CARD_SIZE_ID,
  POSTCARD_SIZE_ID,
  SQUARE_CARD_SIZE_ID,
  STANDARD_CARD_SIZE_ID,
} from '../../constants'

const defaultProductImageDimensions = { width: 500, height: 500 }
const landscapeCardImageDimensions = { width: 550, height: 388 }
const portraitCardImageDimensions = { width: 350, height: 496 }
const squareCardImageDimensions = { width: 500, height: 500 }

export const getProductImageDimensions = (
  isCardProduct: boolean,
  productSizeId: string,
  isLandscape: boolean | undefined,
) => {
  if (isCardProduct) {
    switch (productSizeId) {
      case STANDARD_CARD_SIZE_ID:
      case LARGE_CARD_SIZE_ID:
      case GIANT_CARD_SIZE_ID:
      case E_CARD_SIZE_ID:
      case POSTCARD_SIZE_ID:
        return isLandscape
          ? landscapeCardImageDimensions
          : portraitCardImageDimensions
      case SQUARE_CARD_SIZE_ID:
      case LARGE_SQUARE_CARD_SIZE_ID:
      case GIANT_SQUARE_CARD_SIZE_ID:
        return squareCardImageDimensions
      default:
        return defaultProductImageDimensions
    }
  }

  return defaultProductImageDimensions
}
