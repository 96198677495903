import { createLocaleTextHook } from '@moonpig/web-core-locale-text'

export const useProductUpsellLocaleText = createLocaleTextHook({
  dictionary: {
    'common.gift_upsell.default_title': {
      'en-GB': (c: { formattedPrice: string }) =>
        `Upgrade for only ${c.formattedPrice} extra!`,
      'nl-NL': (c: { formattedPrice: string }) =>
        `Geef meer voor maar ${c.formattedPrice} extra`,
    },
    'common.gift_upsell.alcohol_title': {
      'en-GB': _ => 'Upgrade to make a big impression!',
      'nl-NL': (c: { formattedPrice: string }) =>
        `Geef meer voor maar ${c.formattedPrice} extra`,
    },
    'common.gift_upsell.link': {
      'en-GB': 'See this version',
      'nl-NL': 'Bekijk dit formaat',
    },
  },
})
