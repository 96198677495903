import React, { FC } from 'react'
import {
  CarouselState,
  CarouselRoot,
  UnstyledCarouselScrollArea,
  CarouselItem,
  CarouselControls,
  CarouselPreviousButton,
  CarouselNextButton,
  Image,
  Box,
  Text,
  SecondaryButton,
  Heading,
} from '@moonpig/launchpad-components'
import { colorValue, spacingPx } from '@moonpig/launchpad-theme'
import { breakpoint, styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { useLocaleText } from './MultiAddOnSelector.locale'
import { AddonsFieldsFragment } from '../../queries/useProductWithAddonsQuery'

const StyledLabel = styled.label<{ highlighted: boolean }>`
  display: flex;
  align-items: center;
  height: 100%;
  width: 6.25rem;
  overflow: hidden;
  cursor: pointer;
  transition: transform 200ms ease-in-out;
  outline-style: ${({ highlighted }) => (highlighted ? 'solid' : 'none')};
  transform: ${({ highlighted }) => (highlighted ? 'scale(1.2)' : 'scale(1)')};
  outline-color: ${colorValue('colorInteractionSelectedState')};
  outline-width: ${spacingPx(2)};
  outline-offset: -${spacingPx(2)};
  ${s({
    bgcolor: 'colorBackground01',
    borderRadius: 2,
    boxShadow: 2,
  })}
  ${breakpoint('lg')} {
    width: 8.125rem;
    outline-width: ${spacingPx(3)};
    outline-offset: -${spacingPx(3)};
  }
`

const StyledRadioButton = styled.input`
  appearance: none;
  inset: 0;
  ${s({ bgcolor: 'colorBackground01', m: 0, p: 0, border: 0 })}
`

const StyledCarouselRoot = styled(CarouselRoot)`
  ${s({
    px: { xs: 5, md: 6 },
  })}
`

const StyledCarouselScrollArea = styled(UnstyledCarouselScrollArea)`
  ${s({
    bgcolor: 'colorBackground01',
    py: 8,
    borderRadius: 2,
  })}
`

const StyledCarouselItem = styled(CarouselItem)`
  ${s({
    pl: { xs: 6, lg: 8 },
  })}
  &:last-child {
    ${s({
      pr: { xs: 6, lg: 8 },
    })}
  }
`

const Carousel: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <CarouselState>
      <StyledCarouselRoot>
        <StyledCarouselScrollArea>{children}</StyledCarouselScrollArea>
        <CarouselControls>
          <CarouselPreviousButton />
          <CarouselNextButton />
        </CarouselControls>
      </StyledCarouselRoot>
    </CarouselState>
  )
}

interface AddonsCarouselProps {
  addons: AddonsFieldsFragment[]
  highlightedAddon: AddonsFieldsFragment
  onChange: (addon: AddonsFieldsFragment) => void
  onAddToBasket: (addon: AddonsFieldsFragment) => void
}

export const AddonsCarousel: FC<AddonsCarouselProps> = ({
  addons,
  highlightedAddon,
  onChange,
  onAddToBasket,
}) => {
  const localiseText = useLocaleText()
  return (
    <Box>
      <Box marginX={{ xs: -5, md: -6 }}>
        <Carousel>
          {addons.map(addon => {
            const isHighlighted = highlightedAddon.sku === addon.sku
            return (
              <StyledCarouselItem key={addon.sku}>
                <StyledLabel
                  className="focus-visible"
                  highlighted={isHighlighted}
                >
                  <StyledRadioButton
                    type="radio"
                    name="addon"
                    checked={isHighlighted}
                    aria-label={`${addon.title}. ${addon.price.display}`}
                    onChange={() => onChange(addon)}
                  />
                  <Image width="100%" src={addon.image.url} aria-hidden />
                </StyledLabel>
              </StyledCarouselItem>
            )
          })}
        </Carousel>
      </Box>
      <Box
        mt={6}
        aria-hidden
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box minWidth={'0px'}>
          <Heading
            level="h4"
            data-testid="mp-shared-products-addons-carousel-title"
            typography={{
              xs: 'typeMobileDisplay03',
              md: 'typeDesktopDisplay05',
            }}
            display="block"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {highlightedAddon.title}
          </Heading>
          <Text typography="typeBodyLabel" mt={4} display="block">
            {highlightedAddon.price.display}
          </Text>
        </Box>
        <Box>
          <SecondaryButton
            type="button"
            width="max-content"
            ml={6}
            aria-label={localiseText(
              'multi_addons.accessibility.add_this_item',
              { title: highlightedAddon.title },
            )}
            onClick={() => onAddToBasket(highlightedAddon)}
          >
            {localiseText('multi_addons.add_this_item')}
          </SecondaryButton>
        </Box>
      </Box>
    </Box>
  )
}
