import React, { FC } from 'react'
import { HeartToggle } from '@moonpig/launchpad-components'
import { useLocaleText } from '../ProductTile/ProductTile.locale'

type Props = {
  title: string
  isFavourited: boolean
  onFavouriteToggle: () => void
  isCard?: boolean
  className?: string
}

export const FavouriteButton: FC<Props> = ({
  title,
  isFavourited,
  onFavouriteToggle,
  isCard,
  className,
}) => {
  const localiseText = useLocaleText()
  const lables = {
    addToFavourites: isCard
      ? localiseText('card_product_tile.add_to_favourites')
      : localiseText('non_card_product_tile.add_to_favourites', title),
    removeFromFavourites: isCard
      ? localiseText('card_product_tile.remove_from_favourites')
      : localiseText('non_card_product_tile.remove_from_favourites', title),
  }

  return (
    <div className={className} data-testid="shared-products-favourite-button">
      <HeartToggle
        key={title}
        isToggled={isFavourited}
        onToggle={onFavouriteToggle}
        ariaLabel={
          isFavourited ? lables.removeFromFavourites : lables.addToFavourites
        }
      />
    </div>
  )
}
