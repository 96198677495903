import { trackGAEvent } from '@moonpig/web-core-analytics'
import { ProductInfoProduct } from '../../../types'
import { createPersonaliseGAEvent } from '../../../analytics'

type Args = {
  product: ProductInfoProduct
  quantity: number
  pageComponent: string
}

export const createPersonaliseProductAction =
  ({ product, pageComponent, quantity }: Args) =>
  () => {
    const productData = {
      id: product.id,
      name: product.title,
      price: product.masterVariant.price.centAmount,
      quantity,
      category: product.category.name,
      variant: product.masterVariant.title,
    }

    trackGAEvent(
      createPersonaliseGAEvent({
        componentName: pageComponent,
        productData,
      }),
    )
  }
