import type { CardVariantsEnabled } from '../types'

type IDs = keyof CardVariantsEnabled

const CARD_VARIANT_IDS: Record<IDs, number> = {
  'e-cards': 135,
  'standard-cards': 1,
  'large-cards': 5,
  'small-cards': 8,
  'giant-cards': 133,
  'post-cards': 14,
  'square-cards': 13,
  'large-square-cards': 79,
  'giant-square-cards': 137,
}

export const createCardVariantIdsEnabledArray = (
  cardVariantsEnabled: CardVariantsEnabled,
) => {
  return (Object.keys(CARD_VARIANT_IDS) as Array<IDs>).reduce(
    (variants, variant) => {
      if (cardVariantsEnabled[variant]) {
        variants.push(CARD_VARIANT_IDS[variant])
      }
      return variants
    },
    [] as number[],
  )
}
