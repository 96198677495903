import { Box, Text } from '@moonpig/launchpad-components'
import React, { FC, useEffect, useState } from 'react'
import { useLocaleText } from './MultiAddOnSelector.locale'
import { AddonsCarousel } from './AddonsCarousel'
import { AddonDetailsAccordion } from './AddonDetailsAccordion'
import { SingleAddonDetails } from './SingleAddonDetails'
import { SelectedAddonDetails } from './SelectedAddonDetails'
import {
  trackAccordionSectionClick,
  trackAddonSelected,
  trackHighlightedAddon,
} from './tracking'
import { AddonsFieldsFragment } from '../../queries/useProductWithAddonsQuery'

type MultiAddOnSelectorProps = {
  addons: AddonsFieldsFragment[]
  selectedAddon?: AddonsFieldsFragment | null
  onAddClick: (addon: AddonsFieldsFragment) => void
  onRemoveClick: () => void
}

export const MultiAddOnSelector: FC<MultiAddOnSelectorProps> = ({
  addons,
  selectedAddon,
  onAddClick,
  onRemoveClick,
}) => {
  const localiseText = useLocaleText()

  const [highlightedAddon, setHighlightedAddon] =
    useState<AddonsFieldsFragment>(addons[0])

  const isAddonSelected = !!selectedAddon

  const addToBasketHandler = (addon: AddonsFieldsFragment) => {
    onAddClick(addon)
    trackAddonSelected({
      addon,
      index: addons.indexOf(addon),
      addonsLength: addons.length,
    })
  }

  useEffect(() => {
    setHighlightedAddon(addons[0])
  }, [addons])

  return (
    <Box data-testid="mp-shared-products-multi-addon-selector">
      <Text
        as="h2"
        typography={{ xs: 'typeMobileDisplay03', md: 'typeDesktopDisplay05' }}
        aria-label={localiseText('multi_addons.accessibility.title')}
      >
        {localiseText('multi_addons.section.title')}
      </Text>
      <div aria-live="polite">
        {isAddonSelected && (
          <Box mt={4}>
            <SelectedAddonDetails
              selectedAddon={selectedAddon}
              onRemoveClick={onRemoveClick}
            />
          </Box>
        )}
      </div>
      {!isAddonSelected && (
        <Box mt={6}>
          {addons.length > 1 ? (
            <AddonsCarousel
              addons={addons}
              highlightedAddon={highlightedAddon}
              onChange={addon => {
                setHighlightedAddon(addon)
                trackHighlightedAddon({ sku: addon.sku })
              }}
              onAddToBasket={addToBasketHandler}
            />
          ) : (
            <SingleAddonDetails
              addon={highlightedAddon}
              onAddToBasket={addToBasketHandler}
            />
          )}
          {highlightedAddon.description && (
            <Box mt={6}>
              <AddonDetailsAccordion
                addon={highlightedAddon}
                onValueChange={(value: string) => {
                  trackAccordionSectionClick({
                    value,
                    sku: highlightedAddon.sku,
                  })
                }}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}
