/* eslint-disable no-plusplus */
import { Reducer } from 'react'
import type { ProductInfoProduct, Variant } from '../../types'
import {
  ActionReset,
  ActionSelectQuantity,
  ActionSelectVariant,
  ActionSelectAddon,
  ActionSetProduct,
  ProductState,
  ActionSetIsGroupCardSelected,
} from './types'
import { DEFAULT_QUANTITY } from './constants'
import { E_CARD_SIZE_ID } from '../../constants'

export const findVariantOrDefault = (
  product: ProductInfoProduct,
  variantKey?: string,
) => {
  let masterVariant: Variant | undefined
  for (let index = 0; index < product.variants.length; index++) {
    const element = product.variants[index]
    if (element.key === product.masterVariant.key) {
      masterVariant = element
    }
    if (element.key === variantKey) {
      return element
    }
  }

  return masterVariant || product.variants[0]
}

export const reducer: Reducer<
  ProductState,
  | ActionSelectVariant
  | ActionSetProduct
  | ActionSelectQuantity
  | ActionSelectAddon
  | ActionSetIsGroupCardSelected
  | ActionReset
> = (state, action) => {
  switch (action.type) {
    case 'SET_PRODUCT': {
      const initialVariant = findVariantOrDefault(
        action.payload,
        state.initialVariantKey,
      )
      return {
        ...state,
        product: action.payload,
        initialVariant,
        variant: initialVariant,
      }
    }
    case 'SELECT_VARIANT': {
      return {
        ...state,
        variant: findVariantOrDefault(state.product, action.payload),
        quantity:
          action.payload === E_CARD_SIZE_ID ? DEFAULT_QUANTITY : state.quantity,
      }
    }
    case 'SELECT_QUANTITY': {
      return {
        ...state,
        quantity: action.payload,
      }
    }
    case 'SELECT_ADDON': {
      return {
        ...state,
        addon: action.payload,
      }
    }
    case 'SET_IS_GROUP_CARD_SELECTED': {
      return {
        ...state,
        isGroupCardSelected: action.payload,
      }
    }
    case 'RESET': {
      return {
        ...state,
        quantity: DEFAULT_QUANTITY,
      }
    }
    /* istanbul ignore next */
    default:
      return state
  }
}
