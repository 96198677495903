import { gql } from '@moonpig/web-core-graphql'

export const designFields = gql`
  fragment DesignFontFaceFields on DesignFontFace {
    id
    name
    urls {
      ttf
      woff
      woff2
    }
  }

  fragment DesignColorFields on DesignColor {
    id
    name
    value
  }

  fragment DesignTextShadowFields on DesignTextShadow {
    color
    offsetX
    offsetY
  }

  fragment DesignTextEditableFields on DesignTextEditable {
    defaultText
    allowBlankText
    allowDefaultText
    maxCharacters
    textTransform
    editableNum
    customNum
  }

  fragment DesignTemplateFields on DesignTemplate {
    id
    elements {
      __typename
      ... on DesignElementImageStatic {
        num
        id
        x
        y
        width
        height
        url
        maskUrl
        opacity
        rotation
      }
      ... on DesignElementImageUpload {
        num
        id
        x
        y
        width
        height
        url
        maskUrl
        opacity
        rotation
        required
      }
      ... on DesignElementImageCollage {
        num
        id
        x
        y
        width
        height
      }
      ... on DesignElementTextStatic {
        num
        id
        x
        y
        width
        height
        horizontalAlignment
        verticalAlignment
        legacyLineSpacing
        fontFace {
          ...DesignFontFaceFields
        }
        fontSize
        color {
          ...DesignColorFields
        }
        shadow {
          ...DesignTextShadowFields
        }
        opacity
        rotation
        text
      }
      ... on DesignElementTextStyled {
        num
        id
        x
        y
        width
        height
        horizontalAlignment
        verticalAlignment
        legacyLineSpacing
        fontFace {
          ...DesignFontFaceFields
        }
        fontSize
        color {
          ...DesignColorFields
        }
        shadow {
          ...DesignTextShadowFields
        }
        opacity
        rotation
        editableText {
          ...DesignTextEditableFields
        }
        availableFontFaces {
          ...DesignFontFaceFields
        }
        availableFontSizes
        availableColors {
          ...DesignColorFields
        }
      }
      ... on DesignElementTextPlain {
        num
        id
        x
        y
        width
        height
        horizontalAlignment
        verticalAlignment
        legacyLineSpacing
        fontFace {
          ...DesignFontFaceFields
        }
        fontSize
        color {
          ...DesignColorFields
        }
        shadow {
          ...DesignTextShadowFields
        }
        opacity
        rotation
        editableText {
          ...DesignTextEditableFields
        }
      }
      ... on DesignElementTextPlaceholder {
        num
        id
        x
        y
        width
        height
        horizontalAlignment
        verticalAlignment
        legacyLineSpacing
        fontFace {
          ...DesignFontFaceFields
        }
        fontSize
        color {
          ...DesignColorFields
        }
        shadow {
          ...DesignTextShadowFields
        }
        opacity
        rotation
        textParts {
          __typename
          ... on DesignTextPartStatic {
            text
          }
          ... on DesignTextPartEditable {
            defaultText
            allowBlankText
            allowDefaultText
            maxCharacters
            textTransform
            editableNum
            customNum
          }
        }
      }
      ... on DesignElementImageDynamic {
        num
        id
        x
        y
        width
        height
        rotation
        opacity
        dynamic {
          id
          format
        }
        textParts {
          __typename
          ... on DesignTextPartEditable {
            defaultText
            allowBlankText
            allowDefaultText
            maxCharacters
            textTransform
            editableNum
            customNum
          }
        }
      }
      ... on DesignElementShapeRectangle {
        num
        id
        x
        y
        width
        height
        opacity
        rotation
        fillColor
        outline {
          color
          width
        }
      }
    }
  }

  fragment DesignCustomisationsFields on DesignCustomisations {
    template {
      imageUpload {
        elementId
        editedImage {
          id
        }
        sourceImage {
          id
          transform {
            position {
              x
              y
            }
            rotation
            scale
          }
        }
      }
      imageDynamic {
        elementId
        textParts
      }
      imageCollage {
        elementId
        editedImage {
          id
          url
        }
        grid {
          id
          cells {
            id
            anchors {
              top
              right
              bottom
              left
            }
            content {
              image {
                sourceImage {
                  id
                  scale
                  offsetX
                  offsetY
                }
              }
            }
          }
          gutters {
            id
            offset
            orientation
            anchors {
              start
              end
            }
          }
        }
      }
      textStyled {
        elementId
        text
        fontFaceId
        fontSize
        colorId
        horizontalAlignment
        verticalAlignment
      }
      textPlain {
        elementId
        text
      }
      textPlaceholder {
        elementId
        textParts
      }
    }
    handwriting {
      sourceImage {
        id
        transform {
          position {
            x
            y
          }
          rotation
          scale
        }
      }
      image {
        id
      }
    }
    groupMessages {
      sourceImage {
        id
        transform {
          position {
            x
            y
          }
          rotation
          scale
        }
      }
      image {
        id
        url
      }
    }
    imageUpload {
      sourceImage {
        id
        transform {
          position {
            x
            y
          }
          rotation
          scale
        }
      }
      image {
        id
        url
      }
    }
    overlay {
      overlays {
        ... on DesignOverlayInlay {
          __typename
          index
          x
          y
          height
          width
          rotation
          content {
            inlayId
            image {
              __typename
              ... on RendererInlayImage {
                url
              }
              ... on Error {
                message
              }
            }
          }
        }
        ... on DesignOverlaySticker {
          index
          x
          y
          width
          height
          rotation
          content {
            stickerId
            urls {
              editor
              editorThumbnail
              preview
              print
            }
          }
        }
        ... on DesignOverlayText {
          index
          x
          y
          width
          height
          rotation
          content {
            text
            fontFaceId
            fontSize
            colorId
            horizontalAlignment
            verticalAlignment
          }
        }
        ... on DesignOverlayImage {
          index
          x
          y
          width
          height
          rotation
          content {
            editedImage {
              id
              url
            }
            sourceImage {
              id
              transform {
                position {
                  x
                  y
                }
                rotation
                scale
              }
            }
          }
        }
      }
    }
  }

  fragment DesignFields on Design {
    id
    createdAt
    updatedAt
    store
    productKey
    version
    state
    layout
    scenes {
      id
      title
      width
      height
      template {
        ...DesignTemplateFields
      }
      customisations {
        ...DesignCustomisationsFields
      }
    }
  }
`
