import { Alert, Heading, Text } from '@moonpig/launchpad-components'
import React, { FC } from 'react'
import { IconInformation } from '@moonpig/launchpad-assets'
import { useCoreLocaleText } from '@moonpig/web-core-locale-text'
import { styled } from '@moonpig/launchpad-utils'

const StyledHeading = styled(Heading)`
  margin-bottom: 0;
  &::first-letter {
    text-transform: uppercase;
  }
`

export const SoldOutBanner: FC = () => {
  const localiseText = useCoreLocaleText()

  return (
    <Alert variant="info" icon={IconInformation}>
      <StyledHeading
        level="h4"
        typography={{ xs: 'typeMobileDisplay05', md: 'typeDesktopDisplay06' }}
      >
        {localiseText('common.sold_out')}
      </StyledHeading>
      <Text mb={0}>
        {localiseText('common.this_product_is_currently_not_available_to_buy')}
      </Text>
    </Alert>
  )
}
