import React from 'react'
import {
  IconCardLandscapeGiant,
  IconCardLandscapeLarge,
  IconCardLandscape,
  IconCardPortraitGiant,
  IconCardPortraitLarge,
  IconCardPortrait,
  IconCardSquareGiant,
  IconCardSquareLarge,
  IconCardSquare,
  IconCardEcard,
} from '@moonpig/launchpad-assets'
import { IconFlower } from './icons/IconFlower'
import { IconFlowerLarge } from './icons/IconFlowerLarge'
import {
  STANDARD_CARD_SIZE_ID,
  LARGE_CARD_SIZE_ID,
  GIANT_CARD_SIZE_ID,
  SQUARE_CARD_SIZE_ID,
  LARGE_SQUARE_CARD_SIZE_ID,
  GIANT_SQUARE_CARD_SIZE_ID,
  E_CARD_SIZE_ID,
  STANDARD_FLOWER_SIZE_ID,
  LARGE_FLOWER_SIZE_ID,
} from '../../constants'

const VARIANT_SIZE_TO_ICON_MAP = {
  [STANDARD_CARD_SIZE_ID]: <IconCardPortrait />,
  [LARGE_CARD_SIZE_ID]: <IconCardPortraitLarge />,
  [GIANT_CARD_SIZE_ID]: <IconCardPortraitGiant />,
  [`${STANDARD_CARD_SIZE_ID}-landscape`]: <IconCardLandscape />,
  [`${LARGE_CARD_SIZE_ID}-landscape`]: <IconCardLandscapeLarge />,
  [`${GIANT_CARD_SIZE_ID}-landscape`]: <IconCardLandscapeGiant />,
  [SQUARE_CARD_SIZE_ID]: <IconCardSquare />,
  [LARGE_SQUARE_CARD_SIZE_ID]: <IconCardSquareLarge />,
  [GIANT_SQUARE_CARD_SIZE_ID]: <IconCardSquareGiant />,
  [E_CARD_SIZE_ID]: <IconCardEcard />,
  [STANDARD_FLOWER_SIZE_ID]: <IconFlower />,
  [LARGE_FLOWER_SIZE_ID]: <IconFlowerLarge />,
}

export const getVariantIcon = ({
  sizeId,
  isLandscape = false,
}: {
  sizeId: string | number
  isLandscape?: boolean
}): JSX.Element | undefined => {
  return isLandscape
    ? VARIANT_SIZE_TO_ICON_MAP[`${sizeId}-landscape`]
    : VARIANT_SIZE_TO_ICON_MAP[sizeId]
}
