import { trackGAEvent } from '@moonpig/web-core-analytics'
import {
  createAddonItemGAEvent,
  createSelectAddonGAEvent,
} from '../../analytics'
import { AddonItemEventEnum } from '../../types'
import { AddonsFieldsFragment } from '../../queries/useProductWithAddonsQuery'

export const trackHighlightedAddon = ({ sku }: { sku: string }) => {
  trackGAEvent(
    createSelectAddonGAEvent({
      action: 'select',
      sku,
      controlType: 'add-ons carousel',
    }),
  )
}

export const trackAddonSelected = ({
  addon,
  index,
  addonsLength,
}: {
  addon: AddonsFieldsFragment
  index: number
  addonsLength: number
}) => {
  trackGAEvent(
    createAddonItemGAEvent({
      eventType: AddonItemEventEnum.SELECT_ITEM,
      addon,
      index,
      label: `product details | multi add-on selector | ${
        index + 1
      } / ${addonsLength} | ${addon.sku}`,
      listName: 'product details | multi add-on selector',
    }),
  )
}

export const trackAddonDeselected = ({ sku }: { sku: string }) => {
  trackGAEvent(
    createSelectAddonGAEvent({
      action: 'deselect',
      sku,
      controlType: 'remove item button',
    }),
  )
}

export const trackAccordionSectionClick = ({
  value,
  sku,
}: {
  value?: string
  sku: string
}) => {
  trackGAEvent(
    createSelectAddonGAEvent({
      action: value ? 'open accordion section' : 'close accordion section',
      sku,
      controlType: 'add-on details accordion',
    }),
  )
}

export const trackTileProductDetailsClick = ({
  show,
  sku,
}: {
  show: boolean
  sku: string
}) => {
  const trackAction = show ? 'see' : 'hide'
  trackGAEvent(
    createSelectAddonGAEvent({
      action: 'select',
      sku,
      controlType: `${trackAction} product details link`,
    }),
  )
}
