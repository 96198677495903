import React from 'react'
import { Image } from '@moonpig/launchpad-components'
import { CardProductCarouselImage } from './CardProductCarouselImage'

const PRODUCT_SIZE_ID_SUFFIX = `&productSizeId=`
const ZOOM_SCALE = 1.5

type ImageType = {
  url: string
}

type getImageSrcArgs = {
  url: string
  width: number
  productSizeId?: string | number
}
const getImageSrc = ({ url, width, productSizeId }: getImageSrcArgs) =>
  `${url}?w=${width}${PRODUCT_SIZE_ID_SUFFIX}${productSizeId}`

type mapImagesToCarouselItemsArgs = {
  images: ImageType[]
  isCardProduct: boolean
  isPostcard: boolean
  isLandscape: boolean | undefined
  imageDimensions: {
    width: number
    height: number
  }
  productSizeId?: string | number
}
export const mapImagesToCarouselItems = ({
  images,
  isCardProduct,
  isPostcard,
  isLandscape,
  imageDimensions,
  productSizeId,
}: mapImagesToCarouselItemsArgs) => {
  return images.map(({ url }, index) => {
    const { width } = imageDimensions
    const image = {
      src: getImageSrc({
        url,
        width,
        productSizeId,
      }),
      alt: ``,
      lazyLoad: false,
      zoomSrc: getImageSrc({
        url,
        productSizeId,
        width: width * ZOOM_SCALE,
      }),
    }

    return isCardProduct ? (
      <CardProductCarouselImage
        key={url}
        image={image}
        isPortraitPostcardBack={isPostcard && index === 1 && !isLandscape}
        imageDimensions={imageDimensions}
        data-testid={`carousel-image-${index}`}
      />
    ) : (
      <Image
        key={url}
        {...image}
        aspectRatio={1}
        aria-hidden
        data-testid={`carousel-image-${index}`}
      />
    )
  })
}
