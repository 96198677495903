import { BRAND_LABEL } from '@moonpig/web-core-brand/config'
import { AddonItemEventEnum } from '../types'
import { AddonsFieldsFragment } from '../queries/useProductWithAddonsQuery/__generated__/useProductWithAddonsQuery'

type AddonItemEventProps = {
  eventType: AddonItemEventEnum
  addon: AddonsFieldsFragment
  index: number
  label: string
  listName: string
}

export const createAddonItemGAEvent = ({
  eventType,
  addon,
  index,
  label,
  listName,
}: AddonItemEventProps) => {
  const value = addon.price.centAmount / 100
  const gaIndex = index + 1
  const currency = addon.price.currencyCode
  return {
    event: eventType,
    ecommerce: {
      currency: currency.toLocaleUpperCase(),
      value,
      items: [
        {
          index: gaIndex,
          item_brand: BRAND_LABEL.toLowerCase(),
          item_category: 'add-ons',
          item_category2: undefined,
          item_category3: 'in-stock',
          item_category4: undefined,
          item_category5: undefined,
          item_id: addon.sku.toLowerCase(),
          item_list_name: listName.toLowerCase().substring(0, 100),
          item_name: addon.title.toLowerCase(),
          item_variant: undefined,
          price: Number(value.toFixed(2)),
          quantity: 1,
        },
      ],
    },
    // For GA3 backward compatibility //
    event_data: {
      category: 'product action',
      action: eventType.replace('_', ' '),
      label: label.toLowerCase().substring(0, 100),
      non_interaction: true,
      value: Math.round(value),
    },
    // Web only //
    content_data: undefined,
    discount_data: undefined,
    error_data: undefined,
    // For GTM //
    application_data: {
      datalayer_version: 1,
    },
  }
}
