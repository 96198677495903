import { FormattedVariant, FormattedVariantCategory } from '../../types'

export const formatVariantCategories = (
  variants: FormattedVariant[],
  id: string,
) => {
  const categories: FormattedVariantCategory[] = []

  variants.forEach(variant => {
    const categoryName = variant.label.split('-')[0].trim()
    let category = categories.find(c => c.label === categoryName)

    if (!category) {
      category = {
        label: categoryName,
        variants: [],
        price: variant.price,
        minimumQuantity: variant.minimumQuantity,
        sku: variant.sku || /* istanbul ignore next */ `${id}-${variant.value}`,
      } as FormattedVariantCategory

      categories.push(category)
    }

    const variantLabel = variant.label
      .replace(`${categoryName} - `, '')
      .replace('(', '')
      .replace(')', '')

    category.variants.push({
      label: variantLabel,
      price: variant.price,
      fullPrice: variant.fullPrice,
      value: variant.value,
      selected: variant.selected,
      inStock: variant.inStock,
      minimumQuantity: variant.minimumQuantity,
      sku: variant.sku || /* istanbul ignore next */ `${id}-${variant.value}`,
      bundles: variant.bundles,
    })
    category.variants.sort((a, b) => (a.value > b.value ? 1 : -1))
  })

  return categories
}
