export const isSponsoredCheck = (
  productIsSponsored?: boolean,
  productIsInternallyPromoted?: boolean,
) => {
  if (productIsSponsored) return 'true'

  if (productIsInternallyPromoted && !productIsSponsored) return 'internal'

  return 'false'
}
