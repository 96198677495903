import { gql } from '@moonpig/web-core-graphql'

export const AddCardAndDigitalGiftToBasketGQL = gql`
  mutation addCardAndDigitalGiftToBasket(
    $input: AddCardAndDigitalGiftToBasketInput!
  ) {
    addCardAndDigitalGiftToBasket(input: $input) {
      __typename
      ... on Basket {
        id
        version
        totalItems
        lineItems {
          title
          sku
          digitalGift {
            id
            sku
            title
          }
        }
      }
      ... on Error {
        message
      }
    }
  }
`
