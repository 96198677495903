export const getCurrencySymbolFromCode = (currencyCode: string): string => {
  const zeroValue = 0
  return zeroValue
    .toLocaleString('en', {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 0,
    })
    .replace('0', '')
}
