import { trackGAEvent } from '@moonpig/web-core-analytics'
import { PromotionImpressionProps } from '../types'
import { createImpressionGAEvent } from '../../../analytics/createImpressionGAEvent'

export const createPromotionImpressionAction =
  () => (payload: PromotionImpressionProps) => {
    const label = `${payload.componentName} | ${payload.description}`

    trackGAEvent(
      createImpressionGAEvent({
        category: 'promotion',
        action: 'view promotion',
        label,
        item_id: payload.itemId,
      }),
    )
  }
