import { useMutation } from '@moonpig/web-core-graphql'
import { logger } from '@moonpig/web-core-monitoring'
import {
  AddToFavouritesListMutation,
  AddToFavouritesListMutationVariables,
  AddToFavouritesListGQL,
  AddToFavouritesList_addToFavouritesList_products,
} from '@moonpig/web-shared-favourites'

export function useAddToFavouritesList() {
  const [addToFavouriteListMutation] = useMutation<
    AddToFavouritesListMutation,
    AddToFavouritesListMutationVariables
  >(AddToFavouritesListGQL)

  const addToFavouritesList = (productId: string) => {
    return addToFavouriteListMutation({
      variables: { productIds: [productId] },
    })
      .then(({ data }) => {
        const products = data?.addToFavouritesList.products.filter(Boolean)

        return Promise.resolve(
          products as AddToFavouritesList_addToFavouritesList_products[],
        )
      })
      .catch(error => {
        logger.fixToday('Favourites - Failed to add product to favourites', {
          productId,
          data: JSON.stringify(error),
        })
      })
  }

  return { addToFavouritesList }
}
