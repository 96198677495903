import React, { FC, useEffect, useState } from 'react'
import { Region } from '@moonpig/web-core-types'
import {
  Grid,
  Box,
  Flex,
  Text,
  PrimaryButton,
  SecondaryButton,
  Container,
} from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { useCoreLocaleText } from '@moonpig/web-core-locale-text'

enum StickyCtaStatus {
  glideOut = 'glideOut',
  glideIn = 'glideIn',
  hidden = 'hidden',
}

enum StickyCtaTopOffset {
  MOONPIG = 134,
  GREETZ = 136,
}

type StyledCtaBoxProps = {
  stickyCtaTopOffset: number
  showStickyCta: StickyCtaStatus
}

type StickyCtaProps = {
  title: string
  addToBasketText: string
  quantitySelectorElement?: JSX.Element
  ctaButtonInView: boolean
  region?: Region
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  leftWidth?: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rightWidth?: any
  skipEditorEnabled?: boolean
  onAddToBasketClick: () => void
  onPersonaliseClick: () => void
}

const createPixelValue = (value: number) => `${value}px`

const StyledCtaBox = styled.div<StyledCtaBoxProps>`
  @keyframes glideIn {
    0% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(
        ${({ stickyCtaTopOffset }) => createPixelValue(stickyCtaTopOffset * 2)}
      );
    }
  }

  @keyframes glideOut {
    0% {
      transform: translateY(
        ${({ stickyCtaTopOffset }) => createPixelValue(stickyCtaTopOffset * 2)}
      );
    }

    100% {
      transform: translateY(0);
    }
  }
  top: ${({ stickyCtaTopOffset }) => `-${stickyCtaTopOffset}px`};
  left: 0;
  position: fixed;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  z-index: 3;
  ${s({
    bgcolor: 'colorBackground01',
    boxShadow: { lg: 4 },
    borderBottom: 1,
    borderColor: 'palette.eclipse.c140',
    display: { xs: 'none', xl: 'flex' },
    paddingTop: 8,
    paddingBottom: 8,
    paddingRight: 0,
    paddingLeft: 0,
  })}

  ${({ showStickyCta }) =>
    showStickyCta === 'glideIn' &&
    `animation: glideIn 0.5s linear;
    animation-fill-mode: forwards;`}
  ${({ showStickyCta }) =>
    showStickyCta === 'glideOut' &&
    `animation: glideOut 0.5s linear;
  `}
`
export const StickyCta: FC<StickyCtaProps> = ({
  title,
  addToBasketText,
  ctaButtonInView,
  quantitySelectorElement,
  region,
  leftWidth,
  rightWidth,
  skipEditorEnabled,
  onAddToBasketClick,
  onPersonaliseClick,
}) => {
  const [stickyCtaStatus, setStickyCtaStatus] = useState(StickyCtaStatus.hidden)
  const [stickyCtaTopOffset, setStickyCtaTopOffset] = useState(
    StickyCtaTopOffset.MOONPIG,
  )

  const localiseText = useCoreLocaleText()

  useEffect(() => {
    if (region === 'nl') {
      setStickyCtaTopOffset(StickyCtaTopOffset.GREETZ)
    }
  }, [region])

  useEffect(() => {
    if (stickyCtaStatus === StickyCtaStatus.glideIn && ctaButtonInView) {
      setStickyCtaStatus(StickyCtaStatus.glideOut)
    } else if (
      (stickyCtaStatus === StickyCtaStatus.glideOut ||
        stickyCtaStatus === StickyCtaStatus.hidden) &&
      !ctaButtonInView
    ) {
      setStickyCtaStatus(StickyCtaStatus.glideIn)
    } else {
      setStickyCtaStatus(StickyCtaStatus.hidden)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctaButtonInView])

  const stickyCtaPrimaryButtonWidth = () => {
    if (quantitySelectorElement && !skipEditorEnabled) {
      return { xs: 4 / 7, md: 3 / 4 }
    }
    if (quantitySelectorElement && skipEditorEnabled) {
      return '50%'
    }

    return '100%'
  }

  return (
    <StyledCtaBox
      stickyCtaTopOffset={stickyCtaTopOffset}
      showStickyCta={stickyCtaStatus}
      data-testid="lp-find-sticky-cta"
    >
      <Container limitWidth padding={0}>
        <Box width={{ lg: '1200px' }}>
          <Grid gap flex alignItems="center">
            <Box
              width={
                skipEditorEnabled ? /* istanbul ignore next */ 1 / 2 : leftWidth
              }
            >
              <Text
                typography={
                  title.length > 60
                    ? /* istanbul ignore next */ {
                        xs: 'typeDisplay04',
                        md: 'typeDisplay05',
                      }
                    : { xs: 'typeDisplay03', md: 'typeDisplay04' }
                }
                pr={6}
                as="p"
                mb={0}
              >
                {title}
              </Text>
            </Box>
            <Flex
              alignItems="center"
              width={
                skipEditorEnabled
                  ? /* istanbul ignore next */ 1 / 2
                  : rightWidth
              }
            >
              {skipEditorEnabled && (
                <SecondaryButton
                  width={1 / 2}
                  mr={6}
                  onClick={() => onAddToBasketClick()}
                >
                  {localiseText('common.add_to_basket')}
                </SecondaryButton>
              )}
              <PrimaryButton
                width={stickyCtaPrimaryButtonWidth()}
                onClick={() => onPersonaliseClick()}
              >
                {addToBasketText}
              </PrimaryButton>
              {quantitySelectorElement}
            </Flex>
          </Grid>
        </Box>
      </Container>
    </StyledCtaBox>
  )
}
