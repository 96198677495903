interface IQueryParamsBuilder {
  add(param: string, value: string | undefined | null): this
  build(): string
}

class QueryParamsBuilder implements IQueryParamsBuilder {
  _paramStrings: string[]

  constructor() {
    this._paramStrings = []
  }

  add(param: string, value: string | undefined | null) {
    if (value === undefined || value === null) return this
    this._paramStrings.push(`${param}=${value}`)
    return this
  }

  build() {
    if (this._paramStrings.length === 0) return ''
    return `?${this._paramStrings.join('&')}`
  }
}

export const queryParamsBuilder = (): QueryParamsBuilder =>
  new QueryParamsBuilder()
